const modalTranslations = {
  profile: 'Профиль',
  'profile/settings': 'Настройки',
  'profile/transactions': 'История транзакций',
  deposit: 'Депозит',
  withdrawal: 'Вывод',
  smscode: 'Введите код из СМС',
  safe: 'Сейф',
  'profile/loyalty': 'Лояльность',
  providers: 'Провайдеры',
  verifyemail: 'Подтвердить email',
  verifyphone: 'Подтвердить телефон',
};

export const translateModalName = modalName => {
  const translatedName = modalTranslations[modalName];

  return translatedName || modalName;
};
