import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { UserContext } from '../../../context/UserContext';
import { setNotifications } from '../../../redux/slices/notificationsSlice';

export const Callback = () => {
  const { login } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogin = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');

      if (authorizationCode) {
        try {
          const data = await postCrmRequest('/auth/login', { code: authorizationCode, origin: window.location.origin });
          login({
            user: data?.user,
            token: data?.token,
          });
          localStorage.removeItem('ref_code');
          navigate('/');
        } catch (error) {
          dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
        }
      }
    };

    handleLogin();
  }, []);

  return <div />;
};
