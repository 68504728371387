import { IMaskInput } from 'react-imask';

import { getModalIconByMethod } from '../../../../utils/getModalIconByMethod';
import styles from './WithdrawalRequisitesAdd.module.scss';

export const getInputByMethod = (data, handleChange) => {
  const Icon = getModalIconByMethod(data?.paymentMethod);
  if (data?.paymentMethod === 'SBP P2P RUB') {
    return (
      <div className={styles.cardField}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <input
          id='requisiteNumber'
          name='requisiteNumber'
          value={data?.requisiteNumber}
          type='text'
          placeholder='Введите номер телефона'
          onChange={handleChange}
          maxLength={11}
        />
      </div>
    );
  }

  if (data?.paymentMethod === 'MC/VISA/MIR P2P RUB') {
    return (
      <div className={styles.cardField}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <IMaskInput
          id='requisiteNumber'
          name='requisiteNumber'
          mask='0000 0000 0000 0000'
          value={data?.requisiteNumber}
          unmask={true}
          onAccept={value => handleChange({ target: { value } })}
          placeholder='Введите номер карты'
          maxLength={19}
        />
      </div>
    );
  }

  if (data?.paymentMethod === 'A2A RUB') {
    return (
      <div className={styles.cardField}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <input
          id='requisiteNumber'
          name='requisiteNumber'
          value={data?.requisiteNumber}
          type='text'
          placeholder='Введите номер счёта'
          onChange={handleChange}
          maxLength={20}
        />
      </div>
    );
  }
};
