import { useWindowSize } from '../../../../hooks/useWindowSize';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { NavBarHome } from '../../../organisms/NavBar/NavBarHome/NavBarHome';
import { SearchBar } from '../../../organisms/SearchBar/SearchBar';
import styles from './GamesModalWrapper.module.scss';

const GamesModalWrapper = ({ children }) => {
  const { isMobile } = useWindowSize();

  return (
    <ModalOverlay className={styles.navBarModalOverlay}>
      <div className={styles.navBarModal}>
        <div className={styles.navBarModaWrapper}>
          <div className={styles.navBarHeadWrapper}>
            <NavBarHome style={{ marginBottom: '0px' }} />
            {!isMobile && <SearchBar />}
          </div>
          <div className={styles.modalContentWrapper}>
            <div data-block-interactions={true} className={styles.modalContent}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default GamesModalWrapper;
