import { STATIC_URL } from '../../../../../../constants/constants';
import { useContext } from 'react';

import { ThemeContext } from '../../../../../../context/ThemeContext';
import styles from './ProviderItem.module.scss';

export const ProviderItem = ({ name, onClick, darkImage, lightImage }) => {
  const { theme } = useContext(ThemeContext);

  const image = theme === 'dark' ? darkImage : lightImage;
  return (
    <div className={styles.providerItem} onClick={onClick}>
      {image ? (
        <img src={`${STATIC_URL}${image?.replace('__domain__', '')}`} alt={name} />
      ) : (
        <p className={styles.name}>{name.replace(/[-_]/gi, ' ')}</p>
      )}
    </div>
  );
};
