import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import { News } from '../../organisms/News/News';
import styles from './NewsPage.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Новости</title>
    <meta
      name='description'
      content='Будьте в курсе последних новостей казино Восход. Узнавайте первыми о новых играх, акциях и изменениях на платформе.'
    />
  </Helmet>
);

const NewsPage = () => {
  const { t } = useTranslation('News');
  const { isMobile } = useWindowSize();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {theme === 'dark' ? <ArrowLeftSvg /> : <ArrowLeftDarkSvg />}
            <h1 className={styles.title}>{t('News')}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <News />
        </div>
      </div>
      {!isMobile && <Footer />}
      {isMobile && <NavBarBottom />}
    </>
  );
};

export default NewsPage;
