import { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import { NavBarBottom } from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import { News } from '../../organisms/News/News';
import { Bonuses } from '../_modals/Bonuses/Bonuses';
import { FAQ } from '../FAQ/FAQ';
import { Home } from '../Home/Home';
import { Rules } from '../Rules/Rules';
import styles from './MainPage.module.scss';

Modal.setAppElement('#root');

export const MainPage = () => {
  const [content, setContent] = useState('Home');

  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const changeContent = newContent => {
    setContent(newContent);
    if (newContent === 'Home') {
      navigate('/');
    } else if (typeof newContent === 'string') {
      navigate(`/${newContent.toLowerCase()}`);
    }
  };

  const componentsMap = {
    Home: <Home />,
    FAQ: <FAQ changeContent={changeContent} />,
    News: <News changeContent={changeContent} />,
    Rules: <Rules changeContent={changeContent} />,
    Bonuses: <Bonuses changeContent={changeContent} />,
  };
  
  return (
    <>
      <Header />
      <div className={styles.contentWrapper}>{componentsMap[content]}</div>

      {/*TODO: не забыть убрать навбар при открытой модалке */}
      {isMobile && <NavBarBottom />}
      {content === 'Home' && <Footer />}
    </>
  );
};
