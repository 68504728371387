import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '../components/pages/404/NotFoundPage';
import { TechnicalWorks } from '../components/pages/500/TechnicalWorks';
import { Callback } from '../components/pages/Auth/Callback';
import { BonusesPage } from '../components/pages/BonusesPage/BonusesPage';
import { FAQ } from '../components/pages/FAQ/FAQ';
import LoyaltyPage from '../components/pages/LoyaltyPage/LoyaltyPage';
import { MainPage } from '../components/pages/MainPage/MainPage';
import NewsPage from '../components/pages/News/NewsPage';
import { NewsDetailPage } from '../components/pages/NewsPage/NewsDetailPage';
import { Rules } from '../components/pages/Rules/Rules';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}>
        <Route path='/profile/*' element={<MainPage />} />
        <Route path='/deposit' element={<MainPage />} />
        <Route path='/withdrawal/*' element={<MainPage />} />
        <Route path='/safe' element={<MainPage />} />
        <Route path='/recent' element={<MainPage />} />
        <Route path='/category' element={<MainPage />} />
        <Route path='/category/:id' element={<MainPage />} />
        <Route path='/providers' element={<MainPage />} />
        <Route path='/providers/:id' element={<MainPage />} />
        <Route path='/new' element={<MainPage />} />
        <Route path='/category/games' element={<MainPage />} />
        <Route path='/game/:id' element={<MainPage />} />
      </Route>
      <Route path='/bonuses-page' element={<BonusesPage />} />
      <Route path='/rules-page' element={<Rules />} />
      <Route path='/FAQ-page' element={<FAQ />} />
      <Route path='/news' element={<NewsPage />} />
      <Route path='/news/:id' element={<NewsDetailPage />} />
      <Route path='/technical-works' element={<TechnicalWorks />} />
      <Route path='/callback/*' element={<Callback />} />
      <Route path='/test-500' element={<TechnicalWorks />} />
      <Route path='/loyaltyPage' element={<LoyaltyPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
