import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import InstagramSvg from '../../../assets/components/Footer/InstagramSvg.svg?react';
import MasterCard from '../../../assets/components/Footer/MasterCard.svg?react';
import P2P from '../../../assets/components/Footer/P2P.svg?react';
import Sbp from '../../../assets/components/Footer/sbp.svg?react';
import TGChat from '../../../assets/components/Footer/TGChat.svg?react';
import TgSvg from '../../../assets/components/Footer/TgSvg.svg?react';
import Tinkoff from '../../../assets/components/Footer/tinkoff.svg?react';
import Visa from '../../../assets/components/Footer/VISA.svg?react';
import VK from '../../../assets/components/Footer/VK.svg?react';
import XSvg from '../../../assets/components/Footer/XSvg.svg?react';
import { GamesModalContext } from '../../../context/GamesModalContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Footer.module.scss';

export const Footer = () => {
  const navigate = useNavigate();
  const { isDesktop, isTablet } = useWindowSize();
  const { openModal } = useContext(GamesModalContext);

  if (isDesktop || isTablet) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.footer}>
          <div className={styles.leftContainer}>
            <div className={styles.logo}>
              <LogoComponent variant='big' />
            </div>
            <div className={styles.description}>
              <p>
                Казино Восход (VOCXOD) – официальный сайт нового поколения для игры в слоты, рулетку, блэкджек и другие
                азартные игры онлайн. VOCXOD предлагает уникальную бонусную механику, удобную платформу, безопасность и
                круглосуточную поддержку. Играй онлайн с удобным интерфейсом и мобильной версией!
              </p>
            </div>
            {isDesktop && (
              <div className={styles.links}>
                <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
                  <XSvg />
                </a>
                <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
                  <TgSvg />
                </a>
                <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
                  <InstagramSvg />
                </a>
                <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
                  <VK />
                </a>
                <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
                  <TGChat />
                </a>
              </div>
            )}
          </div>
          <div>
            <div className={styles.rightContainer}>
              <div className={styles.navigation}>
                <h6>Казино</h6>
                <p onClick={() => navigate('/bonuses-page')}>Бонусы</p>
                <p onClick={() => navigate('/loyaltyPage')}>Лояльность</p>
                <p
                  onClick={() => {
                    window.scrollTo(0, 0);
                    openModal('providers');
                  }}
                >
                  Провайдеры
                </p>
              </div>
              <div className={styles.navigation}>
                <h6>Информация</h6>
                <p onClick={() => navigate('/news')}>Новости</p>
                <p onClick={() => navigate('/FAQ-page')}>Вопросы и ответы</p>
                <p onClick={() => navigate('/rules-page')}>Правила</p>
                {/* <p onClick={() => navigate('/')}>Контакты</p>
              <p onClick={() => navigate('/')}>Аффилиатам</p> */}
              </div>
            </div>
            {isTablet && (
              <div className={styles.links}>
                <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
                  <XSvg />
                </a>
                <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
                  <TgSvg />
                </a>
                <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
                  <InstagramSvg />
                </a>
                <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
                  <VK />
                </a>
                <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
                  <TGChat />
                </a>
              </div>
            )}
          </div>
        </div>
        <div className={styles.devider} />
        <div className={styles.techs}>
          <P2P />
          <Tinkoff />
          <Sbp />
          <Visa />
          <MasterCard />
        </div>
        <div className={styles.copyright}>
          Copyright © <span>******</span> All Reserved
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.footer}>
        <div className={styles.logo}>
          <LogoComponent variant='big' />
        </div>
        <p className={styles.description}>
          Казино Восход (VOCXOD) – официальный сайт нового поколения для игры в слоты, рулетку, блэкджек и другие
          азартные игры онлайн. Играй в онлайн казино Восход где угодно и когда угодно со своего мобильного!
        </p>
        <div className={styles.links}>
          <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
            <XSvg />
          </a>
          <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
            <TgSvg />
          </a>
          <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
            <InstagramSvg />
          </a>
          <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
            <VK />
          </a>
          <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
            <TGChat />
          </a>
        </div>
        <div className={styles.refs}>
          <div onClick={() => navigate('/bonuses-page')}>Бонусы</div>
          <div onClick={() => navigate('/loyaltyPage')}>Лояльность</div>
          <div
            onClick={() => {
              window.scrollTo(0, 0);
              openModal('providers');
            }}
          >
            Провайдеры
          </div>
          <div onClick={() => navigate('/news')}>Новости</div>
          <div onClick={() => navigate('/FAQ-page')}>Вопросы и ответы</div>
          <div onClick={() => navigate('/rules-page')}>Правила</div>
          {/* <div onClick={() => navigate('/')}>Контакты</div> */}
        </div>
        <div className={styles.devider} />
        <div className={styles.techs}>
          <P2P />
          <Tinkoff />
          <Sbp />
          <Visa />
          <MasterCard />
        </div>
        <div className={styles.copyright}>
          Copyright © <span>******</span> All Reserved
        </div>
      </div>
    </div>
  );
};
