import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { clearState, setDepositAmount } from '../../../../redux/slices/paymentSlice';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Deposit.module.scss';

export const Deposit = ({ changeModalContent }) => {
  useDisableScroll();
  const { depositAmount, paymentMethod } = useSelector(store => store.paymentSlice);

  const { t } = useTranslation('Deposit');
  const { getCrmRequest, postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const initDeposit = useMutation({
    mutationFn: data => postCrmRequest('/payment/init_payment_request', data),
    onSuccess: data => (document.location.href = data),
    onError: error => {
      dispatch(clearState());
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    },
  });

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    isLoading,
  } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: () => getCrmRequest('/payment/get_allowed_payment_methods'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const handleButtonClick = useCallback(() => {
    if (Number(depositAmount) < paymentMethods?.limits?.payment[paymentMethod]) {
      dispatch(
        setNotifications({
          type: 'error',
          message: 'LESS_THAN_MINIMAL_SUM',
          params: { amount: paymentMethods?.limits?.payment[paymentMethod] },
        }),
      );
    } else {
      initDeposit.mutate(depositData);
    }
  }, [depositAmount, paymentMethod]);

  useEffect(() => {
    if (paymentMethodsError) {
      dispatch(setNotifications({ type: 'error', message: paymentMethodsError?.response?.data?.message }));
    }
  }, [paymentMethodsError]);

  const handleAmountChange = useCallback(e => {
    dispatch(setDepositAmount(e.target.value));
  }, []);

  const depositData = {
    method: paymentMethod,
    amount: depositAmount,
    redirectUrl: window?.location?.origin,
  };

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='deposit' />
        <div className={styles.deposit}>
          <ContentSwitcher active='Deposit' handleClick={changeModalContent} />
          <NumberInput labelText={t('labelText')} name='amount' onChange={handleAmountChange} value={depositAmount} />
          <PaymentMethods header={t('header')} paymentMethods={paymentMethods} isLoading={isLoading} />
          <Button
            disabled={!depositAmount || !paymentMethod}
            buttonText={t('buttonText')}
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};
