import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { openModal } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { setPageFrom, setPaymentState } from '../../../../redux/slices/paymentSlice';
import { Button } from '../../../atoms/Button/Button';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { getInputByMethod } from './getInputByMethod';
import styles from './WithdrawalRequisitesAdd.module.scss';
import { BanksSelect } from '../../../molecules/BanksSelect/BanksSelect';

export const WithdrawalRequisitesAdd = () => {
  useDisableScroll();
  const { paymentMethod, requisiteNumber, bankName } = useSelector(store => store.paymentSlice);
  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const { t } = useTranslation('Withdrawal');

  useEffect(() => {});

  const checkRequisiteMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_check_add_requisite', data),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });
  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const handleChange = e => {
    const regex = /^\d*$/;

    if (paymentMethod === 'SBP P2P RUB' || regex.test(e.target?.value)) {
      dispatch(setPaymentState({ [e.target?.name || 'requisiteNumber']: e.target?.value }));
    }
  };

  const data = {
    reqType: paymentMethod,
    requisiteNumber,
    bankName,
  };

  const handleSubmit = () => {
    if (paymentMethod === 'MC/VISA/MIR P2P RUB' && requisiteNumber.length < 16) {
      dispatch(setNotifications({ type: 'error', message: 'ERROR_REQUISITE_LENGTH' }));
    }

    checkRequisiteMutation.mutate(data, {
      onSuccess: () => {
        // { actionType: 'add_requisite' } - хардкод, который потом надо будет исправлять если модалка появится ещё где-то
        smsMutation.mutate({ actionType: 'add_requisite' });
        dispatch(setPageFrom('withdrawal'));
        dispatch(
          openModal({
            route: '/withdrawal-step4',
          }),
        );
      },
    });
  };

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title={t(`${paymentMethod}.methodName`)} />
        <div className={styles.wrapper}>
          <>
            <label className={styles.cardLabel} htmlFor='requisiteNumber'>
              {t(`${paymentMethod}.label`)}
            </label>
            {getInputByMethod({ paymentMethod, requisiteNumber }, handleChange)}
            <BanksSelect />
            <Button
              buttonText='Продолжить'
              isLoading={checkRequisiteMutation.isPending || smsMutation.isPending}
              onClick={handleSubmit}
            />
          </>
        </div>
      </div>
    </ModalOverlay>
  );
};
