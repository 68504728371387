import clsx from 'clsx';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Login from '../../../../assets/components/Header/LoginButton.svg?react';
import Stripes from '../../../../assets/components/Header/Stripes.svg?react';
import avatar from '../../../../assets/components/Profile/pfp.png';
import { ThemeContext } from '../../../../context/ThemeContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './ProfileIcon.module.scss';

export const ProfileIcon = ({ text, userLoading, onClick, icon = false }) => {
  const { isMobile } = useWindowSize();
  const { theme } = useContext(ThemeContext);

  const navigate = useNavigate();

  const handleOpenModal = route => {
    navigate(route);
  };

  if (!isMobile) {
    return (
      <>
        <button className={styles.desktopWrapper} onClick={onClick || (() => handleOpenModal('/profile'))}>
          <div className={styles.blob} />
          {text === 'Войти' ? (
            <Login className={clsx(styles.icon, theme === 'dark' ? '' : styles.light)} />
          ) : (
            <Stripes className={styles.icon} />
          )}
          <p>{text}</p>
          {icon && <img src={avatar} alt='Аватар' />}
        </button>
      </>
    );
  }

  return (
    <button className={styles.wrapper} onClick={() => handleOpenModal('/profile')}>
      {userLoading ? <p>Loading...</p> : <img src={avatar} alt='Аватар' />}
    </button>
  );
};
