import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 540 && window.innerWidth < 1200);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1200);

  useEffect(() => {
    const windowResizeHandler = () => {
      if (matchMedia(`(max-width: ${540}px)`).matches) {
        setIsMobile(true);
        setIsTablet(false);
        setIsDesktop(false);
      } else if (matchMedia(`(min-width: ${541}px) and (max-width: ${1200}px)`).matches) {
        setIsMobile(false);
        setIsTablet(true);
        setIsDesktop(false);
      } else {
        setIsMobile(false);
        setIsTablet(false);
        setIsDesktop(true);
      }
    };

    window.addEventListener('resize', windowResizeHandler);
    return () => window.removeEventListener('resize', windowResizeHandler);
  }, []);

  return { isMobile, isTablet, isDesktop };
}
