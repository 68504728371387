import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import TribeDarkPng from '../../../assets/components/Header/TribeDark.png';
import TribeLightPng from '../../../assets/components/Header/TribeLight.png';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { closeAllModals, closeCurrentModal } from '../../../redux/slices/modalSlice';
import { clearState } from '../../../redux/slices/paymentSlice';
import { BackButton } from '../../atoms/BackButton/BackButton';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import styles from './ModalHeader.module.scss';

const ModalHeader = ({ title, showTitle = true, showBackArrow = true }) => {
  const { theme } = useContext(ThemeContext);
  const modals = useSelector(state => state.modalSlice.modals);
  const { t } = useTranslation('ModalHeader');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isMobile } = useWindowSize();

  const handleBack = () => {
    const currentModal = modals[modals.length - 1];
    const prevModal = modals[modals.length - 2];

    // Если текущая модалка содержит -step
    if (currentModal?.route.includes('-step')) {
      const firstStep = currentModal.route.split('-step')[0];
      // Ищем индекс первой модалки в стеке с базовым маршрутом
      const firstStepIndex = modals.findIndex(modal => modal.route.startsWith(firstStep));

      // Очищаем все модалки после первой
      for (let i = modals.length - 1; i > firstStepIndex; i--) {
        dispatch(closeCurrentModal());
      }

      if (firstStepIndex !== -1) {
        navigate(modals[firstStepIndex].route);
      } else {
        navigate('/');
      }
      return;
    }

    if (currentModal?.route.includes('/game/')) {
      dispatch(closeAllModals());
      navigate('/');
    }

    // Стандартная навигация назад
    dispatch(closeCurrentModal());
    if (prevModal) {
      navigate(prevModal.route);
    } else {
      navigate('/');
    }
  };

  return (
    <div className={styles.modalHeader}>
      {showTitle && (showBackArrow ? <BackButton handleGoBack={handleBack} /> : null)}

      {!isMobile && (
        <div className={styles.tribe}>
          {theme === 'dark' ? <img src={TribeDarkPng} alt='tribe' /> : <img src={TribeLightPng} alt='tribe' />}
        </div>
      )}
      <div className={styles.headerContentWrapper}>
        {showTitle ? (
          <>
            <h3>{t(title) || title}</h3>
          </>
        ) : (
          <div />
        )}
        <CloseButton
          onClick={() => {
            navigate('/');
            dispatch(closeAllModals());
            dispatch(clearState());
          }}
        />
      </div>
    </div>
  );
};

export default ModalHeader;
