import { ModalContentCategory } from './ui/ModalContentCategory/ModalContentCategory';
import { ModalContentFilteredGames } from './ui/ModalContentFilteredGames/ModalContentFilteredGames';
import { ModalContentNovelties } from './ui/ModalContentNovelties/ModalContentNovelties';
import { ModalContentProviders } from './ui/ModalContentProviders/ModalContentProviders';
import { ModalContentRecent } from './ui/ModalContentRecent/ModalContentRecent';

export const modalGamesRoutes = {
  recent: ModalContentRecent,
  category: ModalContentCategory,
  providers: ModalContentProviders,
  new: ModalContentNovelties,
  'category/games': ModalContentFilteredGames,
  'providers/games': ModalContentFilteredGames,
};
