import CategoryIco from '../../../../../../assets/components/NavBar/CategoryIco.svg?react';
import styles from './CategoryItem.module.scss';

export const CategoryItem = ({ title, img, onClick }) => {
  const Image = img;
  return (
    <div className={styles.categoryItem} onClick={onClick}>
      <Image />
      <span className={styles.categoryName}>{title}</span>
    </div>
  );
};
