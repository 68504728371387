import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { closeAllModals, openModal } from '../../../../redux/slices/modalSlice';
import { getModalContent } from './routes';

const selectLastModal = createSelector(
  state => state.modalSlice.modals,
  modals => modals[modals.length - 1],
);

export const Modal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modal = useSelector(selectLastModal);
  const modals = useSelector(state => state.modalSlice.modals);

  const renderModalContent = useCallback(modal => {
    const Component = getModalContent(modal?.route)?.component;
    return Component ? <Component {...modal.contentProps} /> : 'Контент модалки';
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const modalConfig = getModalContent(currentPath);
    const lastModalRoute = modals[modals.length - 1]?.route;

    if (modalConfig && lastModalRoute !== currentPath) {
      dispatch(
        openModal({
          route: currentPath,
          title: modalConfig.title,
          contentProps: {},
        }),
      );
    } else if (!modalConfig) {
      dispatch(closeAllModals());
    }
  }, [location.pathname]);

  return useMemo(() => {
    if (modals.length === 0) return null;

    return ReactDOM.createPortal(
      <div key={modal?.route} className='modal-backdrop'>
        <div className='modal-content'>{renderModalContent(modal)}</div>
      </div>,
      document.getElementById('modal-root'),
    );
  }, [modal, modals.length, renderModalContent]);
};
