import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import ArrowDown from '../../../assets/components/FAQ/ArrowDown.svg?react';
import styles from './TextAccordion.module.scss';

const TextAccordion = ({ title, description, contentClassName, triggerClassName, rootClassName }) => {
  return (
    <Accordion.Root className={clsx(styles.accordionRoot, rootClassName ?? '')} type='single' collapsible>
      <Accordion.Item className={styles.accordionItem} value='item-1'>
        <Accordion.Trigger className={clsx(styles.accordionTrigger, triggerClassName ?? '')}>
          <h2>{title}</h2>
          <ArrowDown className={styles.accordionChevron} />
        </Accordion.Trigger>
        <Accordion.Content className={clsx(styles.accordionContent, contentClassName ?? '')}>
          {description}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default TextAccordion;
