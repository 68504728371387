import { configureStore } from '@reduxjs/toolkit';

import bonusesSlice from './slices/bonusesSlice';
import gamesSlice from './slices/gamesSlice';
import modalSlice from './slices/modalSlice';
import notificationsSlice from './slices/notificationsSlice';
import paymentSlice from './slices/paymentSlice';
import safeSlice from './slices/safeSlice';

const storeOptions = {
  reducer: {
    safeSlice,
    paymentSlice,
    bonusesSlice,
    gamesSlice,
    notificationsSlice,
    modalSlice,
  },
};

const store = configureStore(storeOptions);

export default store;
