import * as Toast from '@radix-ui/react-toast';
import { useSelector } from 'react-redux';

import { NotificationsToast } from '../../molecules/NotificationsToast/NotificationsToast';
import styles from './Notifications.module.scss';

export const Notifications = () => {
  const { notifications } = useSelector(store => store.notificationsSlice);

  return (
    <Toast.Provider>
      {notifications?.map(item => (
        <NotificationsToast key={item?.message} {...item} />
      ))}
      <Toast.Viewport className={styles.toastViewport} />
    </Toast.Provider>
  );
};
