import { clsx } from 'clsx';
import { useContext } from 'react';

import LogoSupport from '../../../../assets/components/Header/Support.svg?react';
import { UserContext } from '../../../../context/UserContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './Support.module.scss';

const Support = () => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const { isDesktop } = useWindowSize();

  return (
    <div className={clsx(styles.wrapper, 'show-verbox')}>
      <LogoSupport />
      {isDesktop && <p className={styles.text}>24/7</p>}
    </div>
  );
};

export default Support;
