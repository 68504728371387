import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowSvg from '../../../assets/components/Profile/ArrowSvg.svg?react';
import { UserContext } from '../../../context/UserContext';
import { openModal } from '../../../redux/slices/modalSlice';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import { getModalIconByMethod } from '../../../utils/getModalIconByMethod';
import { Button } from '../../atoms/Button/Button';
import DeleteSvg from './delete.svg?react';
import styles from './RequisiteSelect.module.scss';

const getMaskedNumber = number => {
  const reqExp = /(?:79|9)(\d{2})(\d{3})(\d{2})(\d{2})/gm;
  return number.replace(reqExp, (match, g1, g2, g3, g4) => {
    return `+7 (9${g1}) ${g2} ${g3}-${g4}`;
  });
};

export const RequisiteSelect = memo(({ activeRequisite, setActiveRequisite }) => {
  const { paymentMethod } = useSelector(store => store.paymentSlice);
  const [isExpanded, setIsExpanded] = useState(false);
  const { userState } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation('RequisiteSelect');

  const deleteMutation = useMutation({
    mutationFn: id => postCrmRequest('/payment/payout_hide_requisite', { reqId: id }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const Icon = getModalIconByMethod(paymentMethod);

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCardChoose = requisite => {
    setActiveRequisite(requisite);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    deleteMutation.mutate(id);
  };
  const client = userState?.crmUserData;

  const requisitesByMethod = client?.cards?.filter(item => item.type === paymentMethod);

  return (
    <div className={clsx(styles.requisiteSelect)} onClick={toggleDropdown}>
      <label className={styles.label} htmlFor='requisiteInfo'>
        {t(`${paymentMethod}.selectLabel`)}
      </label>
      <div id='requisiteInfo' className={clsx(styles.requisiteDropdown, styles.main)}>
        {activeRequisite ? (
          <>
            <div className={styles.icon}>
              <Icon />
            </div>
            <div className={styles.requisiteInfo}>
              <div
                className={styles.requisiteTitle}
              >{`${t(`${paymentMethod}.requisitTitle`)} ${activeRequisite?.index}`}</div>
              <div className={styles.requisiteNumber}>
                {paymentMethod === 'SBP P2P RUB'
                  ? getMaskedNumber(activeRequisite?.cardNumber)
                  : activeRequisite?.cardNumber}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.requisiteInfo}>
            <div className={styles.requisiteNumberMain}>{t(`${paymentMethod}.chooseRequisit`)}</div>
          </div>
        )}
        <div className={clsx(styles.requisiteArrow, isExpanded ? styles.rotate : '')}>
          <ArrowSvg />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.requisiteOptions}>
          {requisitesByMethod?.length > 0 ? (
            requisitesByMethod.map((requisite, index) => (
              <div
                key={requisite?.id}
                onClick={() => handleCardChoose({ index: index + 1, ...requisite })}
                className={styles.requisiteDropdown}
              >
                <div className={styles.icon}>
                  <Icon />
                </div>
                <div className={styles.requisiteInfo}>
                  <div className={styles.requisiteTitle}>{`${t(`${paymentMethod}.requisitTitle`)} ${index + 1}`}</div>
                  <div className={styles.requisiteNumber}>
                    {paymentMethod === 'SBP P2P RUB' ? getMaskedNumber(requisite?.cardNumber) : requisite?.cardNumber}
                  </div>
                </div>
                <div className={styles.deleteIcon} onClick={e => handleDelete(e, requisite?.id)}>
                  <DeleteSvg />
                </div>
              </div>
            ))
          ) : (
            <p>{t(`${paymentMethod}.notFoundRequisites`)}</p>
          )}
          <Button
            buttonText={t(`${paymentMethod}.submitText`)}
            onClick={() => dispatch(openModal({ route: '/withdrawal-step3' }))}
          />
        </div>
      )}
    </div>
  );
});
