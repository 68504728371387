import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CatSvg from '../../../../assets/components/NavBar/Cat.svg?react';
import FavSvg from '../../../../assets/components/NavBar/Fav.svg?react';
import NovSvg from '../../../../assets/components/NavBar/Nov.svg?react';
import ProSvg from '../../../../assets/components/NavBar/Pro.svg?react';
import { GamesModalContext } from '../../../../context/GamesModalContext';
import styles from './NavBarHome.module.scss';

export const NavBarHome = ({ style }) => {
  const { t } = useTranslation('NavBarHome');
  const { openModal, modalIsOpen, modalContent, setModalContent } = useContext(GamesModalContext);

  useEffect(() => {
    if (!modalIsOpen) {
      setModalContent('');
    }
  }, [modalIsOpen]);

  return (
    <div className={styles.navBarHome} style={style}>
      <button
        onClick={() => openModal('recent')}
        className={`${styles.navBarHomeItem} ${modalContent === 'recent' ? styles.active : ''}`}
      >
        <FavSvg />
        <p>{t('recent')}</p>
        {modalContent === 'recent' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('category')}
        className={`${styles.navBarHomeItem} ${modalContent === 'category' ? styles.active : ''}`}
      >
        <CatSvg />
        <p>{t('category')}</p>
        {modalContent === 'category' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('providers')}
        className={`${styles.navBarHomeItem} ${modalContent === 'providers' ? styles.active : ''}`}
      >
        <ProSvg />
        <p>{t('providers')}</p>
        {modalContent === 'providers' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('new')}
        className={`${styles.navBarHomeItem} ${modalContent === 'new' ? styles.active : ''}`}
      >
        <NovSvg />
        <p>{t('new')}</p>
        {modalContent === 'new' && <div className={styles.navBarHomeItemMark} />}
      </button>
    </div>
  );
};
