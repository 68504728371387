import * as Progress from '@radix-ui/react-progress';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import { UserContext } from '../../../../../context/UserContext';
import useDisableScroll from '../../../../../hooks/useDisableScroll';
import { closeAllModals, closeCurrentModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { prepareNewsImageString } from '../../../../../utils/prepareNewsImageString';
import ModalOverlay from '../../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../../molecules/ModalHeader/ModalHeader';
import styles from './BonusItemModal.module.scss';
import { getButton } from './getButton';
import { STATIC_URL } from '../../../../../constants/constants';

const getProgress = (target, current) => {
  if (!Number.isNaN(current)) {
    return Math.floor((current * 100) / target);
  }
  return 0;
};

export const BonusItemModal = () => {
  useDisableScroll();
  const { clickedBonus, bonuses } = useSelector(store => store.bonusesSlice);
  const {
    userState: { crmUserData },
  } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useContext(UserContext).userState;
  const currentProgress = Math.ceil(user?.crmUserData?.wallets?.code?.credit + user?.crmUserData?.wallets?.code?.air);

  const img = prepareNewsImageString(clickedBonus?.promo?.image, STATIC_URL);

  const activateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/promotion_client_start', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      navigate('/');
      dispatch(closeAllModals());
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_BONUS_ACTIVATE' }));
    },
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const deactivateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/promotion_client_cancel', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      navigate('/');
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_BONUS_DEACTIVATE' }));
    },
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const handleClick = () => {
    if (clickedBonus?.status === 'ALLOW') {
      return activateBonusMutation.mutate({ bonusId: clickedBonus?.id });
    }

    if (clickedBonus?.status === 'IN_PROGRESS') {
      dispatch(closeCurrentModal());
      return deactivateBonusMutation.mutate();
    }
  };

  const inProgressBonus = bonuses?.find(bonus => bonus.status === 'IN_PROGRESS');
  const minDep = clickedBonus?.configByClientType?.[clickedBonus?.colorTicketToUse]?.min;
  const maxDep = clickedBonus?.configByClientType?.[clickedBonus?.colorTicketToUse]?.max;
  const target = clickedBonus?.settings?.targetPercent / 100;

  return (
    <>
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title={clickedBonus?.promo?.ru?.title} />
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {clickedBonus?.status === 'IN_PROGRESS' && (
                  <div>
                    <div className={styles.progressTargets}>
                      <p>{currentProgress || 0}</p>
                      <p>{Math.ceil(clickedBonus?.progressData?.targetBalance)}</p>
                    </div>
                    <Progress.Root
                      className={styles.progressRoot}
                      value={getProgress(Math.ceil(clickedBonus?.progressData?.targetBalance), currentProgress)}
                    >
                      <Progress.Indicator
                        className={styles.progressIndicator}
                        style={{
                          transform: `translateX(-${100 - getProgress(Math.ceil(clickedBonus?.progressData?.targetBalance), currentProgress)}%)`,
                          transition: '1s',
                        }}
                      />
                    </Progress.Root>
                    <div className={styles.titleWrapper}>
                      <p className={styles.title}>Баланс</p>
                      <p className={styles.title}>Цель</p>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.textContainer}>
                <div className={styles.params}>
                  {clickedBonus?.settings?.targetPercent ? (
                    <div className={styles.paramsItem}>
                      <span className={styles.paramsTitle}>Цель:</span>
                      <span className={styles.paramsValue}>x{target}</span>
                    </div>
                  ) : null}

                  {minDep > 0 ? (
                    <>
                      <div className={styles.divider} />
                      <div className={styles.paramsItem}>
                        <span className={styles.paramsTitle}>Минимальный депозит:</span>
                        <span className={styles.paramsValue}>{minDep} ₽</span>
                      </div>
                    </>
                  ) : null}
                  {maxDep > 0 ? (
                    <>
                      <div className={styles.divider} />
                      <div className={styles.paramsItem}>
                        <span className={styles.paramsTitle}>Максимальный депозит:</span>
                        <span className={styles.paramsValue}>{maxDep} ₽</span>
                      </div>
                    </>
                  ) : null}
                  {clickedBonus?.settings?.awardPercent ? (
                    <>
                      <div className={styles.divider} />
                      <div className={styles.paramsItem}>
                        <span className={styles.paramsTitle}>Размер бонуса:</span>
                        <span className={styles.paramsValue}>{clickedBonus?.settings?.awardPercent}%</span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(clickedBonus?.promo?.ru?.full_text),
                  }}
                />
              </div>
            </div>
            {getButton(clickedBonus, handleClick, inProgressBonus)}
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
