import { Loyalty } from '../../Loyalty/Loyalty';
import { Bank } from '../Bank/Bank';
import { Bonuses } from '../Bonuses/Bonuses';
import { BonusItemModal } from '../Bonuses/BonusItemModal/BonusItemModal';
import { Deposit } from '../Deposit/Deposit';
import GameModal from '../GameModal/GameModal';
import { ModalContentCategory } from '../GamesModal/ui/ModalContentCategory/ModalContentCategory';
import { ModalContentFilteredGames } from '../GamesModal/ui/ModalContentFilteredGames/ModalContentFilteredGames';
import { ModalContentNovelties } from '../GamesModal/ui/ModalContentNovelties/ModalContentNovelties';
import { ModalContentProviders } from '../GamesModal/ui/ModalContentProviders/ModalContentProviders';
import { ModalContentRecent } from '../GamesModal/ui/ModalContentRecent/ModalContentRecent';
import { Profile } from '../Profile/Profile';
import { Settings } from '../Settings/Settings';
import { SmsCodeModal } from '../SmsCodeModal/SmsCodeModal';
import { Transactions } from '../Transactions/Transactions';
import { Withdrawal } from '../Withdrawal/Withdrawal';
import { WithdrawalRequisites } from '../WithdrawalRequisites/WithdrawalRequisites';
import { WithdrawalRequisitesAdd } from '../WithdrawalRequisitesAdd/WithdrawalRequisitesAdd';

// Функция для параметризованных урлов
const matchRoute = (pattern, url) => {
  const regexPattern = pattern.replace(/\*/g, '.*');
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(url);
};

export const MODAL_ROUTES = {
  '/category': {
    component: ModalContentCategory,
    title: 'Категория',
  },
  '/category/*': {
    component: ModalContentFilteredGames,
    title: 'Игры категории',
  },
  '/deposit': {
    component: Deposit,
    title: 'Депозит',
  },
  '/new': {
    component: ModalContentNovelties,
    title: 'Новинки',
  },
  '/profile': {
    component: Profile,
    title: 'Профиль',
  },
  '/profile/bonuses': {
    component: Bonuses,
    title: 'Бонусы',
  },
  '/profile/bonuses/bonus': {
    component: BonusItemModal,
    title: 'Бонусы',
  },
  '/profile/loyalty': {
    component: Loyalty,
    title: 'Лояльность',
  },
  '/profile/settings': {
    component: Settings,
    title: 'Настройки',
  },
  '/profile/transactions': {
    component: Transactions,
    title: 'История Транзакций',
  },
  '/providers': {
    component: ModalContentProviders,
    title: 'Провайдеры',
  },
  '/providers/*': {
    component: ModalContentFilteredGames,
    title: 'Игры провайдера',
  },
  '/recent': {
    component: ModalContentRecent,
    title: 'Недавние',
  },
  '/safe': {
    component: Bank,
    title: 'Сейф',
  },
  '/withdrawal': {
    component: Withdrawal,
    title: 'Вывод',
  },
  '/withdrawal-step2': {
    component: WithdrawalRequisites,
    title: 'Вывод',
  },
  '/withdrawal-step3': {
    component: WithdrawalRequisitesAdd,
    title: 'Вывод',
  },
  '/withdrawal-step4': {
    component: SmsCodeModal,
    title: 'Вывод',
  },
};

export const getModalContent = url => {
  if (url.includes('/game/')) {
    return {
      component: GameModal,
      title: 'Game',
    };
  }

  const matchedPattern = Object.keys(MODAL_ROUTES).find(pattern => matchRoute(pattern, url));

  return matchedPattern ? MODAL_ROUTES[matchedPattern] : null;
};
