import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Динамически импортируем все JSON файлы из локалей
const translations = import.meta.glob('/public/locales/ru/**/translation.json', { eager: true });

const resources = {
  ru: {},
};

Object.entries(translations).forEach(([path, translation]) => {
  // Извлекаем название неймспейса из пути
  const namespace = path.split('/locales/ru/')[1].split('/translation.json')[0];

  const ns = namespace || 'common';

  resources.ru[ns] = translation.default || translation;
});

// Получаем список всех неймспейсов
const namespaces = Object.keys(resources.ru);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: namespaces,
    defaultNS: 'common',
    supportedLngs: ['ru'],
    fallbackLng: 'ru',
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
