import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';

import { API_BASE_URL } from '../constants/constants';

const getNews = async ({ pageParam = 1 }) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${API_BASE_URL}/news`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { page: pageParam, perPage: 4 },
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const useInfiniteNews = () => {
  return useInfiniteQuery({
    queryKey: ['news'],
    queryFn: ({ pageParam }) => getNews({ pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return pages.length + 1; // Возвращаем следующую страницу только если есть данные
    },
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });
};
