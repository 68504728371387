import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { GamesModalContext } from '../../../context/GamesModalContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { NavBarHome } from '../../organisms/NavBar/NavBarHome/NavBarHome';
import { News } from '../../organisms/News/News';
import { Recommended } from '../../organisms/Recommended/Recommended';
import { SearchBar } from '../../organisms/SearchBar/SearchBar';
import { modalGamesRoutes } from '../_modals/GamesModal/ModalContent';
import styles from './Home.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>
      Казино Восход {getSEOTitle(window.location.href)} | официальный сайт нового поколения для игры в слоты, рулетку,
      блэкджек и другие азартные игры онлайн
    </title>
    <meta
      name='description'
      content='VOCXOD предлагает уникальную бонусную механику, удобную платформу, безопасность и круглосуточную поддержку. Играй онлайн с удобным интерфейсом и мобильной версией!'
    />
  </Helmet>
);

export const Home = () => {
  const { modalContent, setModalContent, modalIsOpen, setModalIsOpen, closeModal } = useContext(GamesModalContext);
  const location = useLocation();
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (modalGamesRoutes[path]) {
      setModalIsOpen(true);
      setModalContent(path);
    } else {
      setModalIsOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [modalIsOpen]);

  const ModalGamesComponent = modalGamesRoutes[modalContent];
  return (
    <>
      <PageMeta />
      {isDesktop && (
        <div className={styles.desktopSearchWrapper}>
          <NavBarHome />
          <SearchBar />
        </div>
      )}
      {(isTablet || isMobile) && (
        <>
          <SearchBar />
          <NavBarHome />
        </>
      )}
      <Recommended />
      {isMobile && <span className={styles.newsHeader}>Новости</span>}
      <News />

      {/* {ModalGamesComponent && ( */}
      {/*  <Modal*/}
      {/*    isOpen={modalIsOpen}*/}
      {/*    onRequestClose={closeModal}*/}
      {/*    shouldCloseOnOverlayClick={true}*/}
      {/*    className={styles.navBarModal}*/}
      {/*    overlayClassName={styles.navBarModalOverlay}*/}
      {/*  >*/}
      {/*    <div className={styles.navBarModalLine} />*/}
      {/*    <div className={styles.navBarModaWrapper}>*/}
      {/*      <div className={styles.navBarHeadWrapper}>*/}
      {/*        <NavBarHome style={{ marginBottom: '0px' }} />*/}
      {/*        {!isMobile && <SearchBar />}*/}
      {/*      </div>*/}
      {/*      <div className={styles.modalContentWrapper}>*/}
      {/*        <div data-block-interactions={true} className={styles.modalContent}>*/}
      {/*          <ModalGamesComponent />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Modal>*/}
      {/*)} */}
    </>
  );
};
