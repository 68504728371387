import { useNavigate } from 'react-router-dom';

import LogoWallet from '../../../../assets/components/Header/LogoWallet.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import styles from './Wallet.module.scss';

export const Wallet = ({ crmUserData, userLoading }) => {
  const balance = crmUserData && crmUserData?.wallets?.code?.credit;
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  if (!isMobile) {
    return (
      <>
        <div className={styles.desktopWrapper} onClick={() => navigate('/deposit')}>
          <div className={styles.badge}>
            <LogoWallet className={styles.walletIcon} />
          </div>
          <div className={styles.container}>
            <div className={styles.balance}>
              <span>{balance ?? 0}</span>
              <span className={styles.currency}>₽</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.wallet} onClick={() => navigate('/deposit')}>
      <div className={styles.icon}>₽</div>
      {userLoading ? <p>Loading...</p> : <div className={styles.balance}>{balance ?? 0}</div>}
    </div>
  );
};
