import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { openModal } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { setWithdrawalAmount } from '../../../../redux/slices/paymentSlice';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Withdrawal.module.scss';

export const Withdrawal = () => {
  useDisableScroll();
  const { withdrawalAmount, paymentMethod } = useSelector(store => store.paymentSlice);

  const { t } = useTranslation('Withdrawal');
  const { getCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const {
    data: paymentMethods,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: () => getCrmRequest('/payment/get_allowed_payment_methods'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const handleChangeAmount = e => {
    dispatch(setWithdrawalAmount(e.target.value));
  };

  const handleButtonClick = useCallback(() => {
    if (Number(withdrawalAmount) < paymentMethods?.limits?.payout[paymentMethod] || Number(withdrawalAmount) <= 0) {
      dispatch(
        setNotifications({
          type: 'error',
          message: 'LESS_THAN_MINIMAL_SUM_WITHDRAWAL',
          params: { amount: paymentMethods?.limits?.payout[paymentMethod] },
        }),
      );
    } else {
      dispatch(
        openModal({
          route: '/withdrawal-step2',
        }),
      );
    }
  }, [withdrawalAmount, paymentMethod]);

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='withdrawal' />
        <div className={styles.withdrawal}>
          <ContentSwitcher active='Withdrawal' />
          <NumberInput
            labelText={t('labelText')}
            name='amount'
            value={withdrawalAmount}
            onChange={handleChangeAmount}
          />
          <PaymentMethods header={t('header')} paymentMethods={paymentMethods} isLoading={isLoading} />
          <Button
            buttonText={t('buttonText')}
            disabled={!(withdrawalAmount && paymentMethod && withdrawalAmount !== '0')}
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};
