import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './Rules.module.scss';

export const Rules = () => {
  const { t } = useTranslation('Rules');
  const { isMobile } = useWindowSize();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const PageMeta = () => (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Казино Восход {getSEOTitle(window.location.href)} | Правила и соглашения казино Восход</title>
      <meta
        name='description'
        content='Ознакомьтесь с правилами и условиями использования казино Восход. Убедитесь, что вы играете в соответствии с нашими правилами, чтобы обеспечить безопасную и честную игру.'
      />
    </Helmet>
  );

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {theme === 'dark' ? <ArrowLeftSvg /> : <ArrowLeftDarkSvg />}
            <h1 className={styles.title}>{t('Rules')}</h1>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentItem}>
            <h2>Раздел 1. Политика конфиденциальности</h2>
            <p>
              Любое взаимодействие с сайтом, а также процесс создания личного счёта, подразумевает ваше согласие с
              Правилами и Политикой конфиденциальности.
            </p>
            <p>При несогласии с какими-либо пунктами следует прекратить пользование ресурсом.</p>
          </div>

          <div className={styles.contentItem}>
            <h3>Изменения в правилах</h3>
            <p>
              Компания оставляет за собой право редактировать и обновлять Правила, уведомляя об этом путём публикации
              новой редакции на сайте.
            </p>
            <p>В случае несогласия с обновлёнными условиями пользователь может перестать пользоваться ресурсом.</p>
          </div>

          <div className={styles.contentItem}>
            <h3>Возраст и юридические аспекты</h3>
            <p>Услуги доступны только совершеннолетним (не моложе 18 лет или согласно правилам местной юрисдикции).</p>
            <p>
              Самостоятельная обязанность игрока — проверять законность онлайн-игр по месту жительства и при
              необходимости нести налоговые обязательства, если они предусмотрены местными нормами.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>Раздел 2. AML и KYC политика</h2>

            <h3>Идентификация и проверка (KYC)</h3>
            <p>
              С целью предотвращения мошенничества и незаконного происхождения средств Компания оставляет за собой право
              проводить проверку (KYC).
            </p>
            <p>В ходе процедуры могут быть запрошены следующие документы и сведения:</p>
            <p>Паспорт или ID-карта;</p>
            <p>Подтверждение платёжного метода (включая выписки, скриншоты счёта/кошелька, фото банковской карты);</p>
            <p>Подтверждение фактической валидности произведённых платежей (чеки, иные документы).</p>
            <p>На период проверки доступ к счёту может быть приостановлен.</p>
            <p>При обнаружении несоответствия предоставленных данных или нарушений Компания вправе:</p>
            <p>Отказать в предоставлении дальнейших услуг;</p>
            <p>Заблокировать аккаунт;</p>
            <p>Аннулировать имеющийся на счёте баланс.</p>
          </div>

          <div className={styles.contentItem}>
            <h2>Раздел 3. Депозиты и выводы</h2>

            <h3>Пополнение</h3>
            <p>Использовать разрешено только собственные средства, внесённые с личных платёжных реквизитов.</p>

            <h3>Идентификация при выводе</h3>
            <p>
              При выводе выше определённого порога, может потребоваться подтверждение личности и предоставление копии
              документов.
            </p>
            <p>
              При этом сумма депозита должна быть прокручена в играх по принципу «x1». Для настольных игр и аналогичных
              дисциплин в зачет идёт только часть ставки.
            </p>

            <h3>Лимиты на вывод</h3>
            <p>
              Максимальная суточная сумма зависит от статуса игрока, и наивысший лимит составляет 400,000 RUB в день:
            </p>
            <p>Зеленый — 50K RUB в день</p>
            <p>Белый — 50K RUB в день</p>
            <p>Бронза — 100K RUB в день</p>
            <p>Серебро — 150K RUB в день</p>
            <p>Золото — 200K RUB в день</p>
            <p>Платина — 400K RUB в день</p>
            <p>
              Сроки обработок заявок — от нескольких минут до 5 рабочих дней, в зависимости от особенностей платёжных
              систем.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>Раздел 4. Нарушения и мошенничество</h2>

            <h3>Недопустимые действия</h3>
            <p>
              Использование чужих платёжных данных, открытие повторных аккаунтов (“дубликатов”), боты, схемы
              «безрисковой игры», а также любая форма сговора или отмывания средств.
            </p>
            <p>
              В случае выявления подобных фактов Компания вправе заблокировать счёт, аннулировать выигрыши и передать
              информацию в соответствующие органы.
            </p>

            <h3>Безопасность и ответственность</h3>
            <p>
              Пользователь несёт ответственность за сохранность пароля и за все операции, совершённые под его учётными
              данными.
            </p>
            <p>
              Любые технические сбои, зависящие от оборудования или интернет-провайдера пользователя, не являются зоной
              ответственности Компании.
            </p>

            <h2>Бонусная политика</h2>

            <h3>Отыгрыш и ограничения</h3>
            <p>
              Бонусы могут содержать условие отыгрыша (цель). Вывод средств при активном бонусе (или неотыгранном)
              невозможен.
            </p>
            <p>
              Ставки с «минимальным риском» (например, одновременная ставка на красное и чёрное в рулетке) в зачёт
              отыгрыша не идут.
            </p>

            <h3>Лимиты и злоупотребления</h3>
            <p>
              При выявлении систематического использования бездепозитных бонусов или обхода правил (в том числе через
              сторонние промо) Компания вправе аннулировать такие выигрыши и заблокировать счёт.
            </p>

            <h3>Отмена бонуса</h3>
            <p>
              Компания может в одностороннем порядке отозвать бонус при технических ошибках или выявлении
              недобросовестной стратегии.
            </p>

            <h2>Заключительные положения</h2>
            <p>При любых сомнениях рекомендуется обращаться в службу поддержки по адресу: support@vocxod.com.</p>
            <p>В спорных случаях приоритет сохраняется за официальным текстом Правил, размещённым на сайте.</p>
            <p>
              Компания не несёт ответственности за задержки и убытки, вызванные обстоятельствами непреодолимой силы
              (форс-мажором).
            </p>
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
      {isMobile && <NavBarBottom />}
    </>
  );
};
