import { memo } from 'react';

import styles from './TransferInput.module.scss';

export const TransferInputComponent = ({ labelText, currency = '₽', innerLabel, balanceName }) => {
  return (
    <>
      {labelText && <label className={styles.label}>{labelText}</label>}
      <div className={styles.transferField}>
        <span className={styles.currencyIcon}>{currency}</span>
        <p className={styles.innerLabel}>{innerLabel}</p>
        <p className={styles.balanceName}>{balanceName}</p>
      </div>
    </>
  );
};

export const TransferInput = memo(TransferInputComponent);
