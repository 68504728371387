import axios from 'axios';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import SearchIcon from '../../../assets/components/SearchBar/search.svg?react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { GameItem } from '../../pages/_modals/GamesModal/ui/GameItem/GameItem';
import styles from './SearchBar.module.scss';

export const SearchBar = ({ style }) => {
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [searchResults, setSearchResults] = useState();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value.length > 2) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  // для локалки `https://test-elasticbackend.chcplatform.net/search?query=${debouncedValue}&language=ru`
  // для не локалки `${window.location.origin}/search?query=${debouncedValue}&language=ru`
  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        const { data } = await axios.get(`${window.location.origin}/search?query=${debouncedValue}&language=ru`);
        setSearchResults(data);
      };

      fetchData();
    }
  }, [debouncedValue]);

  useClickOutside(ref, () => {
    setIsOpen(false);
    setSearchResults(null);
  });

  return (
    <>
      <div className={clsx(styles.searchContainer, isOpen ? styles.open : '')} ref={ref}>
        <div className={clsx(styles.searchBar, isOpen && styles.openedSearchBar)} style={style}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            onClick={() => setIsOpen(true)}
            value={value}
            onChange={handleChange}
            type='text'
            placeholder='Поиск'
          />
        </div>

        {searchResults && (
          <div className={styles.searchResults}>
            <div className={styles.searchResultsGames}>
              {searchResults?.map(item => item?.type === 'game' && <GameItem key={item?.id} game={item} />)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
