import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amount: '',
  fromBalanceName: 'Реальный',
  toBalanceName: 'Сейф',
};

const safeSlice = createSlice({
  name: 'safeSlice',
  initialState,
  reducers: {
    setAmount(state, action) {
      state.amount = action.payload;
    },
    setFromBalanceName(state, action) {
      state.fromBalanceName = action.payload;
    },
    setToBalanceName(state, action) {
      state.toBalanceName = action.payload;
    },
    clearState: () => initialState,
  },
});

export default safeSlice.reducer;
export const { setAmount, setFromBalanceName, setToBalanceName, clearState } = safeSlice.actions;
