import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requisiteNumber: '',
  depositAmount: '',
  withdrawalAmount: '',
  paymentMethod: '',
  bankName: '',
  pageFrom: '',
};

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    setDepositAmount: (state, action) => {
      state.depositAmount = action.payload;
    },
    setWithdrawalAmount: (state, action) => {
      state.withdrawalAmount = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setPaymentState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setPageFrom: (state, action) => {
      state.pageFrom = action.payload;
    },
    clearState: () => initialState,
  },
});

export default paymentSlice.reducer;
export const {
  setDepositAmount,
  setWithdrawalAmount,
  setPaymentMethod,
  setBankName,
  setPaymentState,
  setPageFrom,
  clearState,
} = paymentSlice.actions;
