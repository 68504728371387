import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API_BASE_URL } from '../constants/constants';
import { setNotifications } from '../redux/slices/notificationsSlice';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const useCrmCall = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showNotification = message => {
    dispatch(setNotifications({ type: 'error', message }));
  };

  const handleError = error => {
    // Логируем все ошибки
    Sentry.captureException(error);

    const status = error?.response?.status;
    const backendMessage = error?.response?.data?.message;

    // Не показываем ошибку вообще
    if (backendMessage === 'ERROR_REFRESH_TOKEN') {
      return;
    }

    // Всегда DEFAULT для 401/405
    if ([401, 405].includes(status)) {
      showNotification('DEFAULT');
      return;
    }

    // Технические работы
    if ([502, 503, 504].includes(status)) {
      navigate('/technical-works');
      return;
    }

    // Проверка на SNAKE_CASE формат
    const isSnakeCase = /^[A-Z_]+$/.test(backendMessage);

    if (backendMessage && isSnakeCase) {
      showNotification(backendMessage);
      return;
    }

    // Все остальные ошибки
    showNotification('DEFAULT');
  };

  const makeRequest = async (method, url, data = {}) => {
    const token = localStorage.getItem('authToken');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    try {
      const response = await (method === 'get' ? apiClient.get(url, config) : apiClient.post(url, data, config));
      return response.data || null;
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  return {
    getCrmRequest: url => makeRequest('get', url),
    postCrmRequest: (url, data) => makeRequest('post', url, data),
  };
};
