import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../../../constants/constants';
import { registerUrl } from '../../../../../../constants/ssoConstants';
import { UserContext } from '../../../../../../context/UserContext';
import styles from './GameItem.module.scss';

export const GameItem = ({ game }) => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const imgSource = game?.main_image || game?.img;
  const img = imgSource?.replace(/__domain__/g, STATIC_URL);

  const handleGameClick = () => {
    if (!isAuthenticated) {
      window.location = registerUrl;
    } else {
      navigate(`/game/${game?.key}`);
    }
  };

  return (
    <div onClick={handleGameClick} className={styles.gameIco}>
      <img src={img} alt={`VOCXOD_${game?.translates?.en?.name || game?.title_en}`} />
    </div>
  );
};
