/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/effect-coverflow';

import * as Accordion from '@radix-ui/react-accordion';
import * as Progress from '@radix-ui/react-progress';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowLeftSvg from '../../../assets/components/Loyalty/ArrowLeftSvg.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';
import useDisableScroll from '../../../hooks/useDisableScroll';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import { Button } from '../../atoms/Button/Button';
import { LoyaltyBadge } from '../../atoms/LoyaltyBadge/LoyaltyBadge';
import ModalOverlay from '../../atoms/ModalOverlay/ModalOverlay';
import { PrivilegeItem } from '../../atoms/privilegeItem/PrivilegeItem';
import ModalHeader from '../../molecules/ModalHeader/ModalHeader';
import TextAccordion from '../../molecules/TextAccordion/TextAccordion';
import styles from './Loyalty.module.scss';

const levelWords = ['Зеленый', 'Белый', 'Бронза', 'Серебро', 'Золото', 'Платина'];
const privilegeList = [
  ['Круглосуточная поддержка', 'Бонус по вторникам', 'Бонус по выходным', 'Бонус на День рождения', 'Личный менеджер'],
  [
    'Круглосуточная поддержка',
    '40% бонус по вторникам',
    '50% до 1500₽ по выходным',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '60% бонус по вторникам',
    '50% до 2500₽ по выходным',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '80% бонус по вторникам',
    '50% до 3500₽ по выходным',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '100% бонус по вторникам',
    '50% до 5000₽ по выходным',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '120% бонус по вторникам',
    '50% до 7500₽ по выходным',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
];

const btnsData = [
  {
    limit: 'Суточный лимит 50 000₽',
    cashback: 'Еженедельный кешбэк 5%',
  },
  {
    limit: 'Суточный лимит 50 000₽',
    cashback: 'Еженедельный кешбэк 5%',
  },
  {
    limit: 'Суточный лимит 100 000₽',
    cashback: 'Еженедельный кешбэк 7%',
  },
  {
    limit: 'Суточный лимит 150 000₽',
    cashback: 'Еженедельный кешбэк 10%',
  },
  {
    limit: 'Суточный лимит 200 000₽',
    cashback: 'Еженедельный кешбэк 12%',
  },
  {
    limit: 'Суточный лимит 400 000₽',
    cashback: 'Еженедельный кешбэк 15%',
  },
];

const getDisabledPrivileges = slideIndex => {
  if (slideIndex === 0) return [0];
  if (slideIndex === 1) return [0, 1, 2];
  if (slideIndex === 2) return [0, 1, 2, 3];
  if (slideIndex === 3) return [0, 1, 2, 3];
  return [0, 1, 2, 3, 4];
};

const getStatusLevel = status => {
  const statusArray = ['green', 'white', 'bronze', 'silver', 'gold', 'platinum'];
  const statusIndex = statusArray.indexOf(status);
  return { level: statusIndex, word: levelWords[statusIndex] };
};

const getProgress = betData => {
  if (betData) {
    const currentBet = betData.sum_bet;
    const total = betData?.statuses[betData?.status].to;
    return Math.floor((currentBet * 100) / total);
  }
  return 0;
};

export const Loyalty = () => {
  useDisableScroll();
  const { getCrmRequest } = useCrmCall();
  const [initProgress, setInitProgress] = useState(0);
  const [slider, setSlider] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const dispatch = useDispatch();

  const { data, error } = useQuery({
    queryKey: ['loyalty'],
    queryFn: () => getCrmRequest('/user/get_client_data'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });
  getProgress(data?.betData);

  useEffect(() => {
    if (slider && data?.client) {
      slider.slideTo(getStatusLevel(data?.client.status).level);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }

    setTimeout(() => {
      setInitProgress(65);
    }, 1000);
  }, []);

  const slideNext = to => {
    if (slider) {
      if (to === 'next') {
        slider.slideNext(300);
      } else if (to === 'prev') {
        slider.slidePrev(300);
      }
    }
  };

  return (
    <>
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title='loyalty' />

          <div className={styles.backWrapper}>
            <div className={styles.topContentBox}>
              <div className={styles.levelWrapper}>
                <LoyaltyBadge level={getStatusLevel(data?.client?.status).level + 1} />
              </div>
              {getStatusLevel(data?.client?.status).level + 1 >= 6 ? (
                <div className={styles.progressFinal}>
                  <p>Вы достигли максимального уровня лояльности</p>
                </div>
              ) : (
                <div className={styles.progressBox}>
                  <div className={styles.progressHeader}>
                    <span>{levelWords[getStatusLevel(data?.client?.status).level]}</span>
                    <span>{levelWords[getStatusLevel(data?.client?.status).level + 1]}</span>
                  </div>
                  <Progress.Root className={styles.progressRoot} value={65}>
                    <Progress.Indicator
                      className={styles.progressIndicator}
                      style={{ transform: `translateX(-${100 - getProgress(data?.betData)}%)`, transition: '1s' }}
                    />
                  </Progress.Root>

                  <div className={styles.progressFooter}>
                    <span>{getProgress(data?.betData) + '%'}</span>
                    <span>100%</span>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.contentWrapper}>
              <div className={styles.mainContent}>
                <div className={styles.sliderWrapper}>
                  <div className={styles.arrowBtn}>
                    <ArrowLeftSvg
                      style={{ visibility: slider?.activeIndex !== 0 ? 'visible' : 'hidden' }}
                      onClick={() => slideNext('prev')}
                    />
                  </div>

                  <Swiper
                    effect='coverflow'
                    spaceBetween={20}
                    slidesPerView={3}
                    centeredSlides={true}
                    modules={[EffectCoverflow]}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 60,
                      modifier: 1,
                      scale: 0.9,
                      slideShadows: false,
                    }}
                    onSlideChange={swiper => {
                      setActiveIndex(swiper.activeIndex);
                    }}
                    onSwiper={swiper => {
                      setSlider(swiper);
                      swiper.activeIndex = getStatusLevel(data?.client.status).level + 1;
                      setActiveIndex(getStatusLevel(data?.client.status).level + 1);
                    }}
                  >
                    {levelWords.map(el => (
                      <SwiperSlide key={el} className={styles.slide}>
                        {el}
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className={styles.arrowBtn}>
                    <div className={clsx(styles.arrowBtn, styles.rotate)}>
                      <ArrowLeftSvg
                        style={{ visibility: slider?.activeIndex < 5 ? 'visible' : 'hidden', strke: 'red' }}
                        onClick={() => slideNext('next')}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.content}>
                  <div className={styles.contentHead}>
                    <Button buttonText={btnsData[slider?.activeIndex]?.limit} className={styles.btn} disableHover />
                    <Button buttonText={btnsData[slider?.activeIndex]?.cashback} className={styles.btn} disableHover />
                  </div>

                  {privilegeList[slider?.activeIndex]?.map((el, i) => {
                    return (
                      <PrivilegeItem
                        key={el}
                        text={el}
                        disable={!getDisabledPrivileges(slider?.activeIndex).includes(i)}
                      />
                    );
                  })}
                </div>
              </div>

              <div className={styles.contentFooter}>
                <div className={styles.accordionWrapper}>
                  <TextAccordion
                    contentClassName={styles.accordionContent}
                    triggerClassName={styles.accordionTrigger}
                    rootClassName={styles.accordionRoot}
                    title='Как начисляются очки?'
                    description='Для повышения уровня лояльности требуется выполнить необходимый оборот ставок. Чем активнее вы
                      играете, тем выше ваш уровень и привилегии!'
                  />
                </div>

                <TextAccordion
                  contentClassName={styles.accordionContent}
                  triggerClassName={styles.accordionTrigger}
                  rootClassName={styles.accordionRoot}
                  title='Как обновляются уровни?'
                  description='Уровни лояльности обновляются каждые 30 дней на основе выполненного оборота ставок. Если
                      необходимый оборот не достигнут, статус понижается до актуального, и для повышения уровня нужно
                      выполнить требования в следующем периоде.'
                />
              </div>
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
