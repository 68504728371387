import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { UserContext } from '../../../../../../context/UserContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setRecentGames } from '../../../../../../redux/slices/gamesSlice';
import { setNotifications } from '../../../../../../redux/slices/notificationsSlice';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import GamesModalWrapper from '../../GamesModalWrapper';
import { sortByIndex } from '../../utils/sortByIndex';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentRecent.module.scss';

export const ModalContentRecent = () => {
  const { recentGames } = useSelector(store => store.gamesSlice);
  const { filteredGames, setFilteredGames } = useContext(GamesModalContext);
  const { isMobile } = useWindowSize();
  const { userState } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();

  const dispatch = useDispatch();

  useDisableInteractionOnScroll();

  const getRecentGames = useMutation({
    mutationFn: () => postCrmRequest(`/games/client_last_games`),
    onSuccess: data => dispatch(setRecentGames(sortByIndex(data))),
  });

  const getGamesByNovelties = useMutation({
    mutationFn: () => postCrmRequest(`/games/games_by_game_group`, { lang: 'ru', key: 'new' }),
    onSuccess: data => setFilteredGames(sortByIndex(data)),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  useEffect(() => {
    getGamesByNovelties.mutate();

    return () => {
      setFilteredGames([]);
    };
  }, []);

  useEffect(() => {
    if (userState?.isAuthenticated) {
      getRecentGames.mutate();
    }
  }, []);

  if (recentGames.length === 0 && getRecentGames.isPending && getGamesByNovelties.isPending) {
    return (
      <GamesModalWrapper>
        <div className={styles.gamesContainer}>
          {Array.from({ length: isMobile ? 9 : 16 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </div>
      </GamesModalWrapper>
    );
  }

  if (recentGames.length === 0 && !getRecentGames.isPending) {
    return (
      <GamesModalWrapper>
        <p className={styles.notFound}>Ничего не найдено</p>
        <div className={styles.gamesContainer}>
          {filteredGames?.map(game => (
            <GameItem key={game?.id} game={game} />
          ))}
        </div>
      </GamesModalWrapper>
    );
  }

  return (
    <GamesModalWrapper>
      <div data-block-interactions={true} className={styles.gamesContainer}>
        {recentGames?.map(game => (
          <GameItem key={game?.id} game={game} />
        ))}
      </div>
    </GamesModalWrapper>
  );
};
