import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { TransactionItem } from '../../../molecules/TransactionItem/TransactionItem';
import styles from './Transactions.module.scss';

export const Transactions = () => {
  useDisableScroll();
  const [activeTab, setActiveTab] = useState('all');

  const { t } = useTranslation('Transactions');
  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const { data: history, error } = useQuery({
    queryKey: ['transactionsHistory'],
    queryFn: () => postCrmRequest('/payment/transaction_history'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const buttons = [
    {
      name: 'Всё',
      content: 'all',
    },
    {
      name: 'Депозиты',
      content: 'payin',
    },
    {
      name: 'Выводы',
      content: 'payout',
    },
  ];

  const filteredHistory = activeTab === 'all' ? history : history?.filter(itm => itm?.type === activeTab);

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='transactions' />

        <div className={styles.transactions}>
          <div className={styles.switcherWrapper}>
            <ContentSwitcher active={activeTab} buttonsMap={buttons} handleClick={setActiveTab} />
          </div>
          <div className={styles.transactionsList}>
            {filteredHistory?.length === 0 ? (
              <p className={styles.notFound}>{t('noFound')}</p>
            ) : (
              filteredHistory?.map(item => <TransactionItem key={item?.date} item={item} />)
            )}
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};
