import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      const obj = action.payload;

      if (obj?.message) {
        const existingNotification = state.notifications.find(item => item.message === obj.message);

        if (existingNotification) {
          existingNotification.repeat = true;
        } else {
          state.notifications.push({ ...obj, repeat: false });
        }
      }
    },
    deleteNotification: (state, action) => {
      state.notifications = state.notifications.filter(item => item.message !== action.payload);
    },
  },
});

export default notificationsSlice.reducer;
export const { setNotifications, deleteNotification } = notificationsSlice.actions;
