import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import ArrowLeftSvg from '../../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import { UserContext } from '../../../../context/UserContext';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { setEnabled, setFilteredBonuses } from '../../../../redux/slices/bonusesSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import Skeleton from '../../../atoms/Skeleton/Skeleton';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './Bonuses.module.scss';
import { ActiveBonusItem } from './ui/ActiveBonusItem/ActiveBonusItem';
import { BonusItem } from './ui/BonusItem/BonusItem';

const getSkeletonCount = (isTablet, isDesktop) => {
  if (isTablet) return 3;
  if (isDesktop) return 4;
  return 2;
};

export const Bonuses = () => {
  useDisableScroll();
  const { isTablet, isDesktop } = useWindowSize();
  const {
    enabled,
    filteredBonuses: { inProgressBonus, allowedBonuses, notAllowedBonuses, completedBonuses },
  } = useSelector(store => store.bonusesSlice);

  const {
    userState: { user },
  } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const { t } = useTranslation('Bonuses');
  const dispatch = useDispatch();

  const userId = user?.userId || null;

  const {
    data: bonuses,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['bonuses', userId],
    queryFn: () => postCrmRequest('/user/promotion_total_list', { userId }),
    enabled,
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(setEnabled(true));
  }, []);

  useEffect(() => {
    dispatch(setFilteredBonuses(bonuses));
  }, [bonuses]);

  return (
    <>
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title='bonuses' />
          <div className={styles.wrapper}>
            {isLoading ? (
              <Skeleton className={styles.bigSkeleton} />
            ) : inProgressBonus ? (
              <ActiveBonusItem data={inProgressBonus} />
            ) : (
              <div className={styles.noBonuses}>
                <p className={styles.noBonusesText}>{t('noActiveBonuses')}</p>
              </div>
            )}

            <div className={styles.backWrapper}>
              <ArrowLeftSvg />
            </div>
            <div className={styles.content}>
              <>
                <p className={styles.blockTitle}>
                  {t('allowedBonuses')} <span className={styles.bonusAmount}>{allowedBonuses?.length}</span>
                </p>
                {isLoading ? (
                  <div className={styles.itemsContainer}>
                    {Array.from({ length: getSkeletonCount(isTablet, isDesktop) }).map((_, i) => (
                      <Skeleton key={`skel-${i}`} className={styles.skeleton} />
                    ))}
                  </div>
                ) : (
                  <div className={styles.itemsContainer}>
                    {allowedBonuses?.length ? (
                      allowedBonuses?.map(item => <BonusItem key={item?.id} data={item} />)
                    ) : (
                      <div className={styles.noBonuses}>{t('noAllowedBonuses')}</div>
                    )}
                  </div>
                )}
              </>
              <>
                <p className={styles.blockTitle}>
                  {t('futureBonuses')} <span className={styles.bonusAmount}>{notAllowedBonuses?.length}</span>
                </p>
                {isLoading ? (
                  <div className={styles.itemsContainer}>
                    {Array.from({ length: getSkeletonCount(isTablet, isDesktop) }).map((_, i) => (
                      <Skeleton key={`skel-${i}`} className={styles.skeleton} />
                    ))}
                  </div>
                ) : (
                  <div className={styles.itemsContainer}>
                    {notAllowedBonuses?.length ? (
                      notAllowedBonuses?.map(item => <BonusItem key={item?.id} data={item} />)
                    ) : (
                      <div className={styles.noBonuses}>{t('noFutureBonuses')}</div>
                    )}
                  </div>
                )}
              </>
              <>
                <p className={styles.blockTitle}>
                  {t('completedBonuses')} <span className={styles.bonusAmount}>{completedBonuses?.length}</span>
                </p>
                {isLoading ? (
                  <div className={styles.itemsContainer}>
                    {Array.from({ length: getSkeletonCount(isTablet, isDesktop) }).map((_, i) => (
                      <Skeleton key={`skel-${i}`} className={styles.skeleton} />
                    ))}
                  </div>
                ) : (
                  <div className={styles.itemsContainer}>
                    {completedBonuses?.length ? (
                      completedBonuses?.map(item => <BonusItem key={item?.id} data={item} />)
                    ) : (
                      <div className={styles.noBonuses}>{t('noCompletedBonuses')}</div>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};

// credit
// air
