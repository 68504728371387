import { useDispatch, useSelector } from 'react-redux';

import { setPaymentMethod } from '../../../redux/slices/paymentSlice';
import { PaymentCard } from '../../atoms/PaymentCard/PaymentCard';
import cardStyles from '../../atoms/PaymentCard/PaymentCard.module.scss';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import styles from './PaymentMethods.module.scss';

const disabledMethods = ['CRYPTO'];

export const PaymentMethods = ({ header, paymentMethods, isLoading }) => {
  const { paymentMethod } = useSelector(store => store.paymentSlice);

  const dispatch = useDispatch();

  const handleCardClick = methodName => {
    dispatch(setPaymentMethod(methodName));
  };

  return (
    <div className={styles.methodsContainer}>
      <p className={styles.methodsLabel}>{header}</p>
      <div className={styles.methods}>
        {isLoading
          ? Array.from({ length: 4 }).map((el, i) => (
              <Skeleton key={`payCard-${i}`} className={cardStyles.methodCard} />
            ))
          : paymentMethods?.paymentMethodList
              ?.filter(el => !disabledMethods.includes(el))
              ?.map(el => {
                return (
                  <PaymentCard
                    key={el}
                    className={paymentMethod === el ? styles.selected : ''}
                    paymentMethod={el}
                    onClick={handleCardClick}
                  />
                );
              })}
      </div>
    </div>
  );
};
