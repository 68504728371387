import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { clearState } from '../../../../redux/slices/paymentSlice';
import { Button } from '../../../atoms/Button/Button';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { RequisiteSelect } from '../../../molecules/CardSelect/RequisiteSelect';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import styles from './WithdrawalRequisites.module.scss';

export const WithdrawalRequisites = () => {
  const { withdrawalAmount, paymentMethod } = useSelector(store => store.paymentSlice);
  const [activeRequisite, setActiveRequisite] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { postCrmRequest } = useCrmCall();
  const { t } = useTranslation('Withdrawal');

  useDisableScroll();

  const initWithdrawalMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_init_by_id', data),
    onSuccess: () => {
      queryClient.invalidateQueries(['userBalance']);
      setActiveRequisite(null);
      dispatch(clearState());
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_WITHDRAWAL' }));
      navigate('/');
    },
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const data = {
    cardId: activeRequisite?.id,
    amount: withdrawalAmount,
  };

  useEffect(() => {
    const modalContent = document.querySelector('.ReactModal__Content');
    if (modalContent) modalContent.style.overflowY = 'visible';
    return () => {
      const modalContent = document.querySelector('.ReactModal__Content');
      if (modalContent) modalContent.style.overflowY = 'scroll';
    };
  }, []);

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title={t(paymentMethod)} />

        <div className={styles.wrapper}>
          <RequisiteSelect activeRequisite={activeRequisite} setActiveRequisite={setActiveRequisite} />
          <NumberInput labelText='Сумма вывода' name='amount' disabled={true} value={withdrawalAmount || ''} />
          <Button
            buttonText='Вывод средств'
            isLoading={initWithdrawalMutation?.isPending}
            onClick={() => initWithdrawalMutation?.mutate(data)}
            disabled={!activeRequisite}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};
