import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setNotifications } from '../../../../../../redux/slices/notificationsSlice';
import getSEOTitle from '../../../../../../utils/getSEOTitle';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import Spinner from '../../../../../atoms/Spinner/Spinner';
import GamesModalWrapper from '../../GamesModalWrapper';
import { ProviderItem } from '../ProviderItem/ProviderItem';
import styles from './ModalContentProviders.module.scss';

const PageMeta = () => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Казино Восход {getSEOTitle(window.location.href)} | Провайдеры онлайн казино Восход</title>
      <meta
        name='description'
        content='Исследуйте лучших провайдеров игр казино Восход. Мы сотрудничаем с ведущими разработчиками, чтобы предоставить вам разнообразные и качественные игровые автоматы и настольные игры.'
      />
    </Helmet>
  );
};

export const ModalContentProviders = () => {
  const { isMobile } = useWindowSize();

  const { getCrmRequest } = useCrmCall();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: providers,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['providers'],
    queryFn: () => getCrmRequest('/games/providers_list'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const handleClick = name => {
    navigate(`providers/${name}`);
  };

  if (isLoading) {
    return (
      <>
        <PageMeta />
        <GamesModalWrapper>
          {Array.from({ length: isMobile ? 9 : 24 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </GamesModalWrapper>
      </>
    );
  }

  return (
    <>
      <PageMeta />
      <GamesModalWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {providers?.map(({ id, name, dark_image, light_image }) => (
              <ProviderItem
                key={id}
                name={name}
                darkImage={dark_image}
                lightImage={light_image}
                onClick={() => handleClick(name)}
              />
            ))}
          </>
        )}
      </GamesModalWrapper>
    </>
  );
};
