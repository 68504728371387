import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modals: [],
  },
  reducers: {
    openModal: (state, action) => {
      const { route, title, contentProps } = action.payload;

      if (state.modals?.at(-1)?.route !== route) {
        state.modals.push({
          route,
          title,
          contentProps, // объект с props для компонента
        });
      }
    },
    closeCurrentModal: state => {
      state.modals.pop();
    },
    closeAllModals: state => {
      state.modals = [];
    },
    updateTitle: (state, action) => {
      const { index, title } = action.payload;
      if (state.modals[index]) state.modals[index].title = title;
    },
    updateContent: (state, action) => {
      const { index, contentType, contentProps } = action.payload;
      if (state.modals[index]) {
        state.modals[index].contentType = contentType;
        state.modals[index].contentProps = contentProps;
      }
    },
  },
});

export default modalSlice.reducer;
export const { openModal, closeCurrentModal, updateTitle, updateContent, closeAllModals } = modalSlice.actions;
