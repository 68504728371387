import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import { UserContext } from '../../../../context/UserContext';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './Settings.module.scss';
import { ThemeSwitcher } from './ui/ThemeSwitcher';
import { getEmailButtonName, getPhoneButtonName } from './utils/getButtonName';
import { getEmailUrl, getPhoneUrl } from './utils/getModalUrl';

export const Settings = ({ changeModalContent }) => {
  useDisableScroll();
  const [birthDate, setBirthDate] = useState(null);
  const { userState } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const setBirthdateMutation = useMutation({
    mutationFn: date => postCrmRequest('/user/set_date', { birthDate: date }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const mockIsEmailExist = true;
  const mockIsPhoneVerified = false;

  const handleEmailButtonClick = useCallback(() => {
    changeModalContent(getEmailUrl(mockIsEmailExist), `${getEmailButtonName(mockIsEmailExist)} почту`);
  }, [mockIsEmailExist, changeModalContent]);

  const handlePhoneButtonClick = useCallback(() => {
    changeModalContent(getPhoneUrl(mockIsPhoneVerified), `${getPhoneButtonName(mockIsPhoneVerified)} номер`);
  }, [mockIsPhoneVerified, changeModalContent]);

  const handleChangeBirthdate = useCallback(e => {
    setBirthDate(e.target.value);
  }, []);

  const client = userState?.crmUserData;

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='settings' />

        <div className={styles.settings}>
          <ThemeSwitcher />
          <div className={styles.data}>
            <InputWithButton
              isVerified={userState?.user?.isEmailVerified}
              placeholder='usermail@gmail.com'
              labelText='E-mail'
              // buttonText={getEmailButtonName(mockIsEmailExist)}
              name='email'
              type='email'
              // onButtonClick={handleEmailButtonClick}
              value={userState?.user?.email}
              disabled={true}
            />
            <InputWithButton
              isVerified={userState?.user?.isPhoneVerified}
              placeholder='Добавить номер'
              labelText='Номер телефона'
              // buttonText={getPhoneButtonName(mockIsPhoneVerified)}
              name='phone'
              // onButtonClick={handlePhoneButtonClick}
              masked
              mask='+7 (000) 000 00 00'
              value={userState?.user?.phone}
              disabled={true}
            />
            <InputWithButton
              labelText='Пароль'
              placeholder='Введите новый пароль'
              buttonText='Сменить'
              name='password'
              type='password'
            />
            <InputWithButton
              masked
              mask='00.00.0000'
              placeholder='дд.мм.гггг'
              labelText='Дата рождения'
              buttonText={client?.dates?.length ? null : 'Добавить'}
              name='dateOfBirth'
              type='date'
              value={client?.dates?.[0]?.date || birthDate}
              onChange={handleChangeBirthdate}
              onButtonClick={() => setBirthdateMutation.mutate(birthDate)}
              disabled={client?.dates?.length}
            />
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};
