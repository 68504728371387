import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useInfiniteNews } from '../../../hooks/useInfiniteNews';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import styles from './News.module.scss';
import { NewsItem } from './ui/NewsItem';

const getSkeletonCount = (isMobile, isTablet) => {
  if (isMobile) {
    return { perLine: 1, rows: 1 };
  }
  if (isTablet) {
    return { perLine: 2, rows: 1 };
  }
  return { perLine: 4, rows: 2 };
};

export const News = () => {
  const { data = [], error, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading } = useInfiniteNews();
  const { isMobile, isTablet } = useWindowSize();
  const observer = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const lastNewsElementRef = useCallback(
    node => {
      if (isFetchingNextPage || isInitialLoading) return; // Не вызываем, пока идёт начальная загрузка или запрос новой страницы

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1 },
      );

      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage, isInitialLoading],
  );

  if (isInitialLoading) {
    return (
      <div className={styles.news}>
        {Array.from({ length: getSkeletonCount(isMobile, isTablet).rows }).map(el =>
          Array.from({ length: getSkeletonCount(isMobile, isTablet).perLine }).map((_, i) => (
            <Skeleton key={`skel-${i}`} className={styles.skeleton} />
          )),
        )}
      </div>
    );
  }

  return (
    <div className={styles.news}>
      {data?.pages?.map((page, pageIndex) => {
        if (!Array.isArray(page)) return null;
        return page?.map((newsItem, newsIndex) => {
          const isLastItem = pageIndex === data.pages?.length - 1 && newsIndex === page?.length - 1;
          return (
            <NewsItem
              key={newsItem?.id}
              news={data?.pages}
              newsItem={newsItem}
              ref={isLastItem ? lastNewsElementRef : null}
            />
          );
        });
      })}
      {isFetchingNextPage &&
        Array.from({ length: getSkeletonCount(isMobile, isTablet).perLine }).map((_, i) => (
          <Skeleton key={`skel-${i}`} className={styles.skeleton} />
        ))}
    </div>
  );
};
