import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setNotifications } from '../../../../../../redux/slices/notificationsSlice';
import { capitalizeFirstLetter } from '../../../../../../utils/capitalizeFirstLetter';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import GamesModalWrapper from '../../GamesModalWrapper';
import { sortByIndex } from '../../utils/sortByIndex';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentFilteredGames.module.scss';

export const ModalContentFilteredGames = () => {
  const { filteredGames, setFilteredGames, modalName, modalContent, isLoading } = useContext(GamesModalContext);
  // const [displayedGames, setDisplayedGames] = useState([]);
  // const [currentPage, setCurrentPage] = useState(0);

  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const location = useLocation();
  useDisableInteractionOnScroll();

  // const itemsPerPage = 20;
  // const columnCount = isMobile ? 3 : 4;
  // const rowCount = Math.ceil(filteredGames.length / columnCount);

  const getGamesByGameGroup = useMutation({
    mutationFn: key => postCrmRequest(`/games/games_by_game_group`, { lang: 'ru', key }),
    onSuccess: data => {
      setFilteredGames(sortByIndex(data));
    },
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  useEffect(() => {
    const key = location.pathname.split('/').at(-1);
    getGamesByGameGroup.mutate(key);
    return () => {
      setFilteredGames([]);
    };
  }, []);

  // useEffect(() => {
  //   loadMoreItems();
  // }, [filteredGames]);

  // const loadMoreItems = () => {
  //   const start = currentPage * itemsPerPage;
  //   const end = start + itemsPerPage;
  //   const newItems = filteredGames.slice(start, end);

  //   setDisplayedGames(prev => [...prev, ...newItems]);
  //   setCurrentPage(prev => prev + 1);
  // };

  // const Cell = ({ columnIndex, rowIndex, style }) => {
  //   const index = rowIndex * columnCount + columnIndex;
  //   const game = displayedGames[index];

  //   if (!game) return null;

  //   return (
  //     <div style={style}>
  //       <GameItem game={game} />
  //     </div>
  //   );
  // };

  // const onScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
  //   if (scrollHeight - scrollTop - clientHeight < 100) {
  //     loadMoreItems();
  //   }
  // };

  if (filteredGames.length === 0) {
    return (
      <GamesModalWrapper>
        <div className={styles.gamesContainer}>
          {Array.from({ length: isMobile ? 9 : 16 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </div>
      </GamesModalWrapper>
    );
  }

  if (!isLoading && filteredGames.length === 0) {
    return (
      <GamesModalWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ color: 'var(--third-text-color)' }}>Ничего не найдено</p>
        </div>
      </GamesModalWrapper>
    );
  }

  return (
    <GamesModalWrapper>
      <div className={styles.gamesContent}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>{capitalizeFirstLetter(modalName?.current || modalContent)}</p>
        </div>
        <div data-block-interactions={true} className={styles.gamesContainer}>
          {filteredGames?.map(game => (
            <GameItem key={game?.id} game={game} />
          ))}
        </div>
        {/*
         TODO попытка инфинити скролла через библиотеку
         <FixedSizeGrid
          className={styles.gamesContainer}
          columnCount={8}
          columnWidth={134}
          height={200}
          rowCount={3}
          rowHeight={134}
          width={8 * 134}
          onScroll={onScroll}
        >
          {Cell}
        </FixedSizeGrid> */}
      </div>
    </GamesModalWrapper>
  );
};
