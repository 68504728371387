import * as Progress from '@radix-ui/react-progress';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { STATIC_URL } from '../../../../../../constants/constants';
import { UserContext } from '../../../../../../context/UserContext';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setClickedBonus } from '../../../../../../redux/slices/bonusesSlice';
import { openModal } from '../../../../../../redux/slices/modalSlice';
import { prepareNewsImageString } from '../../../../../../utils/prepareNewsImageString';
import styles from './ActiveBonusItem.module.scss';

const getProgress = (target, current) => {
  if (!Number.isNaN(current)) {
    return Math.floor((current * 100) / target);
  }
  return 0;
};

export const ActiveBonusItem = ({ data }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const img = prepareNewsImageString(data?.promo?.image, STATIC_URL);
  const user = useContext(UserContext).userState;
  const currentProgress = Math.ceil(user?.crmUserData?.wallets?.code?.credit + user?.crmUserData?.wallets?.code?.air);

  const handleBonusClick = () => {
    dispatch(setClickedBonus(data));
    //TODO: отрефачить на navigate когда сделаем открытие по прямому урлу
    dispatch(
      openModal({
        route: '/profile/bonuses/bonus',
        contentProps: data?.promo?.ru?.title,
      }),
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          onClick={handleBonusClick}
          className={styles.container}
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div>
            <div className={styles.progressTargets}>
              <p>{currentProgress || 0}</p>
              <p>{Math.ceil(data?.progressData?.targetBalance)}</p>
            </div>
            <Progress.Root
              className={styles.progressRoot}
              value={getProgress(Math.ceil(data?.progressData?.targetBalance), currentProgress)}
            >
              <Progress.Indicator
                className={styles.progressIndicator}
                style={{
                  transform: `translateX(-${100 - getProgress(Math.ceil(data?.progressData?.targetBalance), currentProgress)}%)`,
                  transition: '1s',
                }}
              />
            </Progress.Root>
            <p className={styles.title}>{data?.promo?.ru?.title}</p>
          </div>
        </div>
        {!isMobile && (
          <div className={styles.textContainer}>
            {data?.settings?.targetPercent !== 0 && (
              <>
                <div className={styles.targetContainer}>
                  <p>Цель:</p>
                  <p>x{data?.settings?.targetPercent / 100}</p>
                </div>
              </>
            )}

            {data?.settings?.awardPercent === 0 && (
              <>
                <div className={styles.devider} />
                <div className={styles.targetContainer}>
                  <p>Размер бонуса:</p>
                  <p>x{data?.settings?.awardPercent}</p>
                </div>
              </>
            )}

            <div className={styles.fullText} dangerouslySetInnerHTML={{ __html: data?.promo?.ru?.full_text }} />
          </div>
        )}
      </div>
    </>
  );
};
