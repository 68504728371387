import clsx from 'clsx';

import styles from './LoyaltyBadge.module.scss';

const levelClasses = {
  1: styles.first,
  2: styles.second,
  3: styles.third,
  4: styles.fourth,
  5: styles.fifth,
  6: styles.sixth,
};

export const LoyaltyBadge = ({ level, isLarge }) => {
  return (
    <div className={clsx(styles.wrapper, levelClasses[level])}>
      <div className={clsx(styles.box, isLarge ? styles.large : '')}>
        <p className={clsx(styles.number, isLarge ? styles.largeNumber : '')}>{level}</p>
      </div>
    </div>
  );
};
