import * as Toast from '@radix-ui/react-toast';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { deleteNotification } from '../../../redux/slices/notificationsSlice';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import styles from './NotificationsToast.module.scss';

const titleType = {
  error: styles.error,
  success: styles.success,
  notification: styles.notification,
};

export const NotificationsToast = ({ type, message, repeat, params }) => {
  const { t } = useTranslation('Notifications');
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setOpen(true);
      const newTimer = setTimeout(() => setOpen(false), 5000);
      setTimer(newTimer);
    }
  }, [message]);

  useEffect(() => {
    if (!open && timer) {
      dispatch(deleteNotification(message));
      clearTimeout(timer);
    }
  }, [open, timer]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Toast.Root open={open} className={clsx(styles.toastRoot, repeat && styles.repeatAnimation)}>
      <Toast.Title asChild>
        <div className={clsx(styles.toastTitle, titleType[type])}>
          {t(type)}
          <Toast.Close asChild>
            <CloseButton onClick={handleClose} />
          </Toast.Close>
        </div>
      </Toast.Title>
      <Toast.Description className={styles.toastDescription}>{t(message, params)}</Toast.Description>
    </Toast.Root>
  );
};
