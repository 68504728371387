import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './ContentSwitcher.module.scss';

export const ContentSwitcher = ({ active = 'Deposit', buttonsMap, handleClick }) => {
  const { t } = useTranslation('ContentSwitcher');
  const navigate = useNavigate();

  const defaultButtons = [
    {
      name: t('deposit'),
      content: 'Deposit',
    },
    {
      name: t('withdrawal'),
      content: 'Withdrawal',
    },
    {
      name: t('safe'),
      content: 'Safe',
    },
  ];

  const buttons = buttonsMap || defaultButtons;

  return (
    <div className={styles.contentSwitcher}>
      {buttons.map(item => (
        <button
          key={item.content}
          className={item.content === active ? styles.active : styles.inActive}
          onClick={() => {
            if (buttonsMap) {
              handleClick(item.content.toLowerCase());
            } else {
              navigate(`/${item.content.toLowerCase()}`);
            }
          }}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};
