import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recentGames: [],
};

const gamesSlice = createSlice({
  name: 'gamesSlice',
  initialState,
  reducers: {
    setRecentGames(state, action) {
      state.recentGames = action.payload;
    },
  },
});

export default gamesSlice.reducer;
export const { setRecentGames } = gamesSlice.actions;
