import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginButton from '../../../assets/components/Header/LoginButton.svg?react';
import { authUrl, registerUrl } from '../../../constants/ssoConstants';
import { UserContext } from '../../../context/UserContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { closeAllModals } from '../../../redux/slices/modalSlice';
import { Button } from '../../atoms/Button/Button';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Header.module.scss';
import { ProfileIcon } from './ui/ProfileIcon';
import Support from './ui/Support';
import { Wallet } from './ui/Wallet';

export const Header = ({ disableAuth = false }) => {
  const {
    userState: { isAuthenticated, crmUserData },
  } = useContext(UserContext);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = authUrl;
  };

  const handleRegister = () => {
    window.location.href = registerUrl;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          className={styles.logo}
          onClick={() => {
            navigate('/');
            dispatch(closeAllModals());
          }}
        >
          <LogoComponent variant={isMobile ? 'small' : 'big'} />
        </div>
        {isAuthenticated && !disableAuth ? (
          <>
            {isMobile ? (
              <>
                <div className={styles.btnsWrapper}>
                  <Wallet crmUserData={crmUserData} />
                  <ProfileIcon user={crmUserData} />
                </div>
              </>
            ) : (
              <div className={styles.btnsWrapper}>
                <Wallet crmUserData={crmUserData} />
                <Support />
                <ProfileIcon user={crmUserData} text='Профиль' icon />
              </div>
            )}
          </>
        ) : isMobile ? (
          <div className={styles.btnsWrapper}>
            <Button className={styles.registerButton} buttonText='Регистрация' onClick={handleRegister} />
            <div className={styles.loginButton} onClick={handleLogin}>
              <LoginButton />
            </div>
          </div>
        ) : (
          <div className={styles.btnsWrapper}>
            <Support />
            <Button className={styles.registerButton} buttonText='Регистрация' onClick={handleRegister} />
            <ProfileIcon text='Войти' onClick={handleLogin} />
          </div>
        )}
      </div>
    </div>
  );
};
