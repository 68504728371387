import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import Skeleton from '../../../atoms/Skeleton/Skeleton';
import { GameItem } from './GameItem';
import styles from './GameList.module.scss';

const getGamesCount = (isMobile, isTablet) => {
  if (isMobile) {
    return { total: 6, perLine: 3, linesCount: 2 };
  }
  if (isTablet) {
    return { total: 20, perLine: 5, linesCount: 4 };
  }
  return { total: 14, perLine: 7, linesCount: 2 };
};

export const GameList = () => {
  const { postCrmRequest } = useCrmCall();
  const { isMobile, isTablet } = useWindowSize();
  const { total, perLine, linesCount } = getGamesCount(isMobile, isTablet);

  const { data: games, error } = useQuery({
    queryKey: ['recommendedGames'],
    queryFn: () =>
      postCrmRequest('/games/get_relevant_games', {
        lang: 'ru',
      }),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  if (!games?.length) {
    return (
      <div className={styles.gameList}>
        {Array.from({ length: linesCount }).map(() => {
          return Array.from({ length: perLine }).map((_, i) => (
            <Skeleton key={`skel-${i}`} className={styles.skeleton} />
          ));
        })}
      </div>
    );
  }

  return (
    <div className={styles.gameList}>{games?.map(game => <GameItem key={game?.id} game={game} />).slice(0, total)}</div>
  );
};
