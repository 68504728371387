import clsx from 'clsx';
import { useContext } from 'react';

import AvelibleSvg from '../../../assets/components/Loyalty/AvelibleSvg.svg?react';
import LockSvg from '../../../assets/components/Loyalty/LockSvg.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';
import styles from './PrivilegeItem.module.scss';

export const PrivilegeItem = ({ text, disable = false }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.loyalty}>
      <p className={disable ? styles.disable : styles.active}>{text}</p>
      <div className={clsx(styles.icon, disable ? styles.disable : styles.active)}>
        {disable ? <LockSvg /> : <AvelibleSvg />}
      </div>
    </div>
  );
};
