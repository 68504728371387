import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import ArrowRightSvg from '../../../../assets/components/Profile/ArrowRightSvg.svg?react';
import BonusSvg from '../../../../assets/components/Profile/BonusSvg.svg?react';
import CopySvg from '../../../../assets/components/Profile/CopySvg.svg?react';
import LogOutSvg from '../../../../assets/components/Profile/LogOutSvg.svg?react';
import LoyaltySvg from '../../../../assets/components/Profile/LoyaltySvg.svg?react';
import NotVerifSvg from '../../../../assets/components/Profile/NotVerifSvg.svg?react';
import DeskAvatar from '../../../../assets/components/Profile/pfp.png';
import SettingSvg from '../../../../assets/components/Profile/SettingSvg.svg?react';
import TransactionsSvg from '../../../../assets/components/Profile/TransactionsSvg.svg?react';
import VerifSvg from '../../../../assets/components/Profile/VerifSvg.svg?react';
import { UserContext } from '../../../../context/UserContext';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { closeAllModals } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './Profile.module.scss';
import { WalletDropdown } from './ui/WalletDropdown';

export const Profile = () => {
  useDisableScroll();
  const { logout, userState } = useContext(UserContext);
  const { t } = useTranslation('Profile');
  const navigate = useNavigate();

  const { getCrmRequest } = useCrmCall();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      const response = await getCrmRequest(`/auth/logout`);

      if (response.status === 200) {
        logout();
        navigate('/');
        dispatch(closeAllModals());
      }
    } catch (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleCopy = copyText => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        dispatch(setNotifications({ type: 'notification', message: 'SUCCESS_COPY_ID' }));
      })
      .catch(() => {
        dispatch(setNotifications({ type: 'error', message: 'ERROR_COPY_ID' }));
      });
  };
  const client = userState?.crmUserData;

  return (
    <>
      <ModalOverlay className={clsx(styles.modalOverlay, styles.profileModalOverlay, styles.mobileOverlay)}>
        <div className={clsx(styles.modal, styles.profileModal)}>
          {isMobile && (
            <div className={styles.avatar}>
              <img src={DeskAvatar} alt='TODO' />
              <div className={styles.profileVerif}>{!client?.is_verified ? <VerifSvg /> : <NotVerifSvg />}</div>
            </div>
          )}
          <ModalHeader title='profile' showTitle={!isMobile} showBackArrow={false} />
          <div className={styles.profileModalContent}>
            {!isMobile && (
              <div className={styles.desktopContent}>
                <img src={DeskAvatar} alt='TODO' />
                <WalletDropdown user={client} />
              </div>
            )}
            <div className={styles.userInfo}>
              <div className={styles.userInfoUsername}>
                {client?.username}
                {/*<EditSvg />*/}
              </div>
              <div className={styles.userInfoID}>
                {t('id')}: {client?.id}
                <button className={styles.copyButton} onClick={() => handleCopy(client?.id)}>
                  <CopySvg />
                </button>
              </div>
            </div>

            {isMobile && <WalletDropdown user={client} />}

            <div className={styles.buttonsNav}>
              <div className={styles.buttons} onClick={() => navigate('/deposit')}>
                {t('deposit')}
              </div>
              <div className={styles.buttons} onClick={() => navigate('/withdrawal')}>
                {t('withdrawal')}
              </div>
              <div className={styles.buttons} onClick={() => navigate('/safe')}>
                {t('safe')}
              </div>
            </div>

            <div className={styles.navWrapper}>
              <div className={styles.navComponent} onClick={() => navigate('/profile/bonuses')}>
                <div className={styles.navComponentIco}>
                  <BonusSvg />
                </div>
                {t('bonuses')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div className={styles.navComponent} onClick={() => navigate('/profile/loyalty')}>
                <div className={styles.navComponentIco}>
                  <LoyaltySvg />
                </div>
                {t('loyalty')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div className={styles.navComponent} onClick={() => navigate('/profile/transactions')}>
                <div className={styles.navComponentIco}>
                  <TransactionsSvg />
                </div>
                {t('transactions')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div className={styles.navComponent} onClick={() => navigate('/profile/settings')}>
                <div className={styles.navComponentIco}>
                  <SettingSvg />
                </div>
                {t('settings')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
            </div>

            <button className={styles.logOut} onClick={handleLogout}>
              <div className={styles.logOutWrapper}>
                {t('logout')}
                <LogOutSvg />
              </div>
            </button>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
