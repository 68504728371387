import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import BonusBanner1 from '../../../assets/components/Bonuses/BonusBanner1.png';
import BonusBanner2 from '../../../assets/components/Bonuses/BonusBanner2.png';
import BonusBanner3 from '../../../assets/components/Bonuses/BonusBanner3.png';
import BonusBanner4 from '../../../assets/components/Bonuses/BonusBanner4.png';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './BonusesPage.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Бонусная система</title>
    <meta
      name='description'
      content='Откройте для себя эксклюзивные бонусы онлайн казино Восход. Узнайте о приветственных бонусах, акциях и промокодах, чтобы увеличить свои шансы на выигрыш.'
    />
  </Helmet>
);
export const BonusesPage = () => {
  const { t } = useTranslation('Bonuses');
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {theme === 'dark' ? <ArrowLeftSvg /> : <ArrowLeftDarkSvg />}
            <h1 className={styles.title}>{t('Bonuses')}</h1>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.imageBox}>
            <img src={BonusBanner1} alt='bonus_banner_1' />
            <img src={BonusBanner2} alt='bonus_banner_2' />
            <img src={BonusBanner3} alt='bonus_banner_3' />
            <img src={BonusBanner4} alt='bonus_banner_4' />
          </div>

          <div className={styles.textContent}>
            <h2 className={styles.subtitle}>
              В казино <strong>VOCXOD</strong> вы&nbsp;найдёте уникальные бонусы для удачного старта и яркой игры!
            </h2>
            <p>
              Вместо привычного вейджера, у нас <strong>«цель»</strong> — сумма, которую нужно достичь, чтобы перевести
              бонусные средства на реальный баланс. Механика бонусов с целевым балансом уникальна: вам{' '}
              <strong>не придется</strong> считать вейджер от суммы бонуса, и при крупном выигрыше не нужно будет
              дополнительно докручивать остаток отыгрыша. Игры для выполнения цели собраны в категории{' '}
              <strong>«Доступные с бонусом»</strong>.
            </p>
            <ul>
              <li>
                <strong>Бонус за регистрацию 100%</strong>
                <br />
                Депозит до 3000 RUB
                <br />
                Цель — x3
              </li>
              <li>
                <strong>Бонус вторника</strong>
                <br />
                До 120% (зависит от уровня лояльности)
                <br />
                Депозит до 15 000 RUB
                <br />
                Цель — x3
              </li>
              <li>
                <strong>Бонус выходных</strong>
                <br />
                50% с пятницы по воскресенье
                <br />
                Депозит до 15 000 RUB
                <br />
                Цель — x3
              </li>
              <li>
                <strong>Кешбэк до 15% без цели</strong>
                <br />
                Каждый понедельник активируйте кешбэк в «Мои бонусы» и забирайте его из сейфа. Время на активацию всего
                24 часа!
              </li>
            </ul>
            <p>
              Чтобы воспользоваться предложениями, пройдите регистрацию, убедитесь в наличии средств на счёте и нажмите
              «Активировать». Удачи и ярких впечатлений!
            </p>
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
      {isMobile && <NavBarBottom />}
    </>
  );
};
