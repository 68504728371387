import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bonuses: [],
  filteredBonuses: { inProgressBonus: {}, allowedBonuses: [], notAllowedBonuses: [], completedBonuses: [] },
  enabled: false,
  clickedBonusId: null,
  clickedBonus: {},
};

const bonusesSlice = createSlice({
  name: 'bonusesSlice',
  initialState,
  reducers: {
    setFilteredBonuses: (state, action) => {
      state.bonuses = action.payload;
      const inProgressBonus = action.payload?.find(bonus => bonus.status === 'IN_PROGRESS');
      const otherBonuses = action.payload?.filter(bonus => bonus.status !== 'IN_PROGRESS');

      state.filteredBonuses = {
        allowedBonuses: otherBonuses?.filter(bonus => bonus?.tickets?.available > 0),
        notAllowedBonuses: otherBonuses?.filter(bonus => !bonus?.tickets || bonus?.tickets?.total === 0),
        completedBonuses: otherBonuses?.filter(bonus => bonus?.tickets?.used > 0 && bonus?.tickets?.available === 0),
        inProgressBonus,
      };
    },

    setEnabled: (state, action) => {
      state.enabled = action.payload;
    },
    setClickedBonus: (state, action) => {
      state.clickedBonus = action.payload;
    },
  },
});

export default bonusesSlice.reducer;
export const { setFilteredBonuses, setEnabled, setClickedBonus } = bonusesSlice.actions;
