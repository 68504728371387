import { clsx } from 'clsx';
import { memo } from 'react';
import { IMaskInput } from 'react-imask';

import VerifSvg from '../../../assets/components/Profile/VerifSvg.svg?react';
import styles from './InputWithButton.module.scss';

const InputWithButtonComponent = ({
  name,
  type = 'text',
  labelText,
  placeholder,
  buttonText,
  buttonStyle,
  value,
  currency,
  disabled,
  buttonDisabled,
  onChange = () => {},
  onButtonClick,
  isVerified,
  children,
  masked,
  mask,
  unmask = false,
}) => {
  const Button = buttonText ? (
    buttonStyle === 'reversed' ? (
      <div className={clsx(styles.reversedButton, buttonDisabled && styles.disabled)} onClick={onButtonClick}>
        {buttonText}
      </div>
    ) : (
      <button
        className={clsx(styles.inputButton, buttonDisabled && styles.disabled)}
        type='button'
        onClick={onButtonClick}
      >
        {buttonText}
      </button>
    )
  ) : null;

  return (
    <>
      {labelText && (
        <label className={clsx(styles.label, disabled && styles.disabled)} htmlFor={name}>
          {labelText}
        </label>
      )}
      <div className={clsx(styles.inputWithButtonContainer, disabled && styles.disabled)}>
        {isVerified && (
          <div className={styles.verified}>
            Подтверждено <VerifSvg />
          </div>
        )}
        {currency && <div className={clsx(styles.currencyIcon, disabled && styles.disabled)}>{currency}</div>}
        {masked ? (
          <IMaskInput
            mask={mask}
            value={value}
            unmask={unmask}
            onAccept={value => onChange({ target: { value, name } })}
            disabled={disabled}
            className={clsx(styles.inputWithButton, currency && styles.currencyPadding, disabled && styles.disabled)}
            placeholder={placeholder}
          />
        ) : (
          <input
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            className={clsx(styles.inputWithButton, currency && styles.currencyPadding, disabled && styles.disabled)}
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        )}
        {children || Button}
      </div>
    </>
  );
};

/**
 * Отображает текстовое поле с кнопкой. Может включать метку, иконку валюты и индикатор верификации.
 *
 * @param {string} props.name Имя для input.
 * @param {string} [props.type='text'] Тип input (по умолчанию "text").
 * @param {string} [props.labelText] Текст метки (label).
 * @param {string} [props.placeholder] Подсказка внутри input.
 * @param {string} props.buttonText Текст кнопки.
 * @param {string} [props.buttonStyle] Стиль кнопки (например, "reversed").
 * @param {string} props.value Текущее значение input.
 * @param {string} [props.currency] Иконка валюты (например, "$").
 * @param {boolean} [props.disabled=false] Отключает input.
 * @param {boolean} [props.buttonDisabled=false] Отключает кнопку.
 * @param {Function} props.onChange Обработчик изменения значения input.
 * @param {Function} props.onButtonClick Обработчик нажатия на кнопку.
 * @param {boolean} [props.isVerified=false] Отображает индикатор верификации.
 */
export const InputWithButton = memo(InputWithButtonComponent);
