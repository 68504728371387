import { useTranslation } from 'react-i18next';

import styles from './PaymentCard.module.scss';
import { getPaymentIcon } from './utils/getPaymentIcon';

export const PaymentCard = ({ paymentMethod, onClick, className }) => {
  const { t } = useTranslation('Withdrawal');
  return (
    <div key={paymentMethod} className={`${styles.methodCard} ${className}`} onClick={() => onClick(paymentMethod)}>
      <span className={styles.methodsIcon}>{getPaymentIcon(paymentMethod)}</span>
      {t(`${paymentMethod}.methodName`)}
    </div>
  );
};
