import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import styles from './ModalOverlay.module.scss';

const ModalOverlay = ({ className, children, overlayClose = true }) => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate('/');
  };

  return (
    <div
      onClick={overlayClose ? closeHandler : null}
      className={className ? className : clsx(styles.modalOverlay, isMobile ? styles.mobileOverlay : '')}
    >
      <div
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalOverlay;
