import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import { UserContext } from '../../../../context/UserContext';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { closeCurrentModal } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { clearState, setAmount } from '../../../../redux/slices/safeSlice';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { CurrencyTransfer } from '../../../organisms/CurrencyTransfer/CurrencyTransfer';
import styles from './Bank.module.scss';
import { getChangeUrl } from './getChangeUrl';
import { getMaxAmount } from './getMaxAmount';

export const Bank = ({ changeModalContent }) => {
  useDisableScroll();
  const { amount, fromBalanceName } = useSelector(store => store.safeSlice);
  const { userState } = useContext(UserContext);

  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const { t } = useTranslation('Bank');
  const dispatch = useDispatch();

  const changeBalance = useMutation({
    mutationFn: () => postCrmRequest(`/payment${getChangeUrl(fromBalanceName)}`, { amount }),
    onSuccess: () => {
      dispatch(closeCurrentModal());
      dispatch(clearState());
      queryClient.invalidateQueries({ queryKey: ['userData'] });
    },
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const handleChange = useCallback(event => {
    dispatch(setAmount(event.target.value));
  }, []);

  const handleMax = useCallback(() => {
    dispatch(setAmount(getMaxAmount(fromBalanceName, userState?.crmUserData?.wallets) ?? ''));
  }, [userState?.crmUserData, fromBalanceName]);

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='safe' />

        <div className={styles.bank}>
          <div className={styles.inputsContainer}>
            <ContentSwitcher active='Safe' handleClick={changeModalContent} />
            <CurrencyTransfer />
            <InputWithButton
              currency={t('currency')}
              placeholder='0.000000'
              buttonText={t('inputButtonText')}
              buttonStyle='reversed'
              labelText='Сумма перевода'
              value={amount}
              onChange={handleChange}
              onButtonClick={handleMax}
            />
          </div>
          <Button
            disabled={Number(amount) === 0}
            buttonText={t('buttonText')}
            onClick={() => (amount > 0 ? changeBalance.mutate() : null)}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};
