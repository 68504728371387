import DOMPurify from 'dompurify';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { STATIC_URL } from '../../../constants/constants';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { convertDateFormat } from '../../../utils/convertDateFormat';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './NewsDetailPage.module.scss';

export const NewsDetailPage = () => {
  const [newsItem, setNewsItem] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state: { news },
  } = useLocation();

  const { isMobile } = useWindowSize();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const newsById = news?.flat()?.find(item => item.id === Number(id));
    setNewsItem(newsById);
  }, [news, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.newsDetailPage}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate(-1)}>
            <div className={styles.titleContainer}>
              {theme === 'dark' ? <ArrowLeftSvg /> : <ArrowLeftDarkSvg />}
              {!isMobile && <h1 className={styles.newsTitle}>{newsItem?.local_ru?.title}</h1>}
            </div>
            <div className={styles.box}>
              {isMobile && <h1 className={styles.newsTitle}>{newsItem?.local_ru?.title}</h1>}
              <p className={styles.newsDate}>{convertDateFormat(newsItem?.publishedAt)}</p>
            </div>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            <div
              className={styles.newsFullText}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newsItem?.local_ru?.full_text),
              }}
            />
          </div>
        </div>
      </div>

      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};
