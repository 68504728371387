import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { openModal } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { setBankName, setPaymentState } from '../../../../redux/slices/paymentSlice';
import { Button } from '../../../atoms/Button/Button';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './SmsCodeModal.module.scss';

export const SmsCodeModal = () => {
  useDisableScroll();
  const { pageFrom, paymentMethod, requisiteNumber, bankName } = useSelector(store => store.paymentSlice);

  const [code, setCode] = useState(new Array(4).fill(''));
  const [timeLeft, setTimeLeft] = useState(60);
  const inputsRef = useRef([]);

  const { postCrmRequest } = useCrmCall();
  const dispatch = useDispatch();

  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onSuccess: () => setTimeLeft(60),
    onError: error => dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message })),
  });

  const queryClient = useQueryClient();

  const smsCode = code?.join('');

  const mutationsMap = {
    withdrawal: useMutation({
      mutationFn: data => postCrmRequest('/payment/payout_add_requisite', data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['userData'] });
        dispatch(
          openModal({
            route: '/withdrawal-step2',
          }),
        );
        dispatch(setPaymentState({ requisiteNumber: '' }));
        dispatch(setBankName(''));
      },
      onError: error => {
        setCode(new Array(4).fill(''));
        setTimeLeft(0);
        dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
      },
    }),
  };

  const submitDataMap = {
    withdrawal: {
      reqType: paymentMethod,
      requisite: {
        reqNum: requisiteNumber,
        bank_key: bankName,
      },
    },
  };

  const mutationData = { ...submitDataMap[pageFrom], smsCode };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      // Проверяем, что введённое значение - цифра
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Если это не последнее поле, переводим фокус на следующее
      if (index < code.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    } else if (value === '') {
      // Если пользователь удаляет символ
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      // Переводим фокус на предыдущее поле
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleResendCode = () => {
    // { actionType: 'add_requisite' } - хардкод, который потом надо будет исправлять если модалка появится ещё где-то
    smsMutation.mutate({ actionType: 'add_requisite' });
  };

  return (
    <ModalOverlay>
      <div className={styles.modal}>
        <ModalHeader title='smsCode' showBackArrow={false} />
        <div className={styles.smsContainer}>
          <p className={styles.modalTitle}>Введите код из СМС для подтверждения личности</p>
          <div className={styles.inputsContainer}>
            {code.map((digit, idx) => (
              <input
                key={idx}
                ref={el => (inputsRef.current[idx] = el)}
                className={clsx(styles.codeInput, mutationsMap[pageFrom]?.isError && styles.errorInput)}
                type='text'
                maxLength='1'
                value={digit}
                onChange={e => handleChange(e, idx)}
              />
            ))}
          </div>
          {timeLeft !== 0 && (
            <p className={styles.retryTimer}>
              Повторить код через: <span>{timeLeft}c</span>
            </p>
          )}
          {mutationsMap[pageFrom]?.isError ? <p className={styles.errorMessage}>Неверный код из СМС</p> : null}
          {timeLeft === 0 && (
            <p className={styles.getNewCode} onClick={handleResendCode}>
              Отправить новый код
            </p>
          )}
          <Button
            buttonText='Продолжить'
            isLoading={smsMutation.isPending || mutationsMap[pageFrom]?.isPending}
            onClick={() => {
              mutationsMap[pageFrom]?.mutate(mutationData);
            }}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};
