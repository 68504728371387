import { clsx } from 'clsx';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import DepSvg from '../../../../assets/components/NavBar/Deposit.svg?react';
import HomeSvg from '../../../../assets/components/NavBar/Home.svg?react';
import SupSvg from '../../../../assets/components/NavBar/Support.svg?react';
import { registerUrl } from '../../../../constants/ssoConstants';
import { UserContext } from '../../../../context/UserContext';
import styles from './NavBarBottom.module.scss';

export const NavBarBottom = () => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.navBarBottom}>
        <div className={styles.buttonsContainer}>
          <button className={clsx(styles.buttonRight, 'show-verbox')}>
            <div className={styles.buttonRightIco}>
              <SupSvg />
            </div>
            Поддержка
          </button>
          <button onClick={() => navigate('/')} className={styles.buttonCenter}>
            <div className={styles.buttonCenterIco}>
              <HomeSvg />
            </div>
            Главная
          </button>
          <button
            onClick={() => (isAuthenticated ? navigate('/deposit') : (window.location = registerUrl))}
            className={styles.buttonLeft}
          >
            <div className={styles.buttonLeftIco}>
              <DepSvg />
            </div>
            Депозит
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBarBottom;
