export const register = [
  {
    title: 'Как зарегистрироваться на сайте?',
    description:
      'Чтобы зарегистрироваться, нажмите на кнопку "Регистрация", введите свой номер телефона и пароль. После заполнения всех полей подтвердите введенный номер с помощью SMS-кода и нажмите "Зарегистрироваться".',
  },
  {
    title: 'Как пройти верификацию?',
    description:
      'Чтобы пройти верификацию, обратитесь в Службу поддержки. Процесс верификации проводится по запросу администрации.',
  },
  {
    title: 'Могу ли я иметь 2 аккаунта на сайте?',
    description:
      'Нет, согласно правилам нашего казино, каждый клиент может иметь только один аккаунт. Создание нескольких аккаунтов запрещено и может привести к блокировке всех связанных учетных записей.',
  },
];

export const transactions = [
  {
    title: 'Как совершить депозит?',
    description:
      'Чтобы пополнить счет, нажмите кнопку "Депозит" в вашем профиле. Выберите удобный способ пополнения: банковская карта, СБП или перевод на счет. Укажите сумму и завершите операцию, следуя инструкциям выбранного метода.',
  },
  {
    title: 'Как совершить вывод средств?',
    description:
      'Чтобы вывести средства, нажмите кнопку "Вывод" в вашем профиле. Выберите удобный способ вывода: банковская карта, СБП или перевод на счет. Укажите сумму, добавьте реквизиты и подтвердите операцию.',
  },
  {
    title: 'Что значит «сейф»?',
    description: 'Сейф - это отдельный вид счета, который служит для хранения средств.',
  },
  {
    title: 'Зачем нужен «сейф»?',
    description:
      'Вы можете использовать сейф в случае, если хотите чтобы ваши средства сохранялись внутри казино, но при этом не отображались на игровом балансе во время игровой сессии. Средства в сейфе надежно хранятся без ограничений по времени, в любой момент вы можете перевести средства из сейфа на игровой баланс (если нет активного бонуса) или вывести из казино при соблюдении лимитов на переводы и выплаты.',
  },
];

export const bonuses = [
  {
    title: 'Как узнать, какие бонусы у меня есть?',
    description:
      'Нажмите в вашем профиле на кнопку "Бонусы", здесь вы найдете полный список доступных и будущих бонусов.',
  },
  {
    title: 'Что значит «цель»?',
    description: `
Чтобы иметь возможность вывести деньги с бонуса необходимо выполнить цель перевода на основной баланс. Для этого нужно достичь общей суммы бонусного и реального балансов установленной условием бонуса цели. Как только требование по цели будет выполнено, сумма бонусного баланса добавится к реальному балансу. Цель перевода на реальный баланс указана в описании каждого бонуса.\n
Например, у вас есть 100% бонус с целью х3. Для его активации вы пополняете баланс на 1000 рублей, на бонусный счет соответственно поступает 1000 рублей. Общий баланс при этом составляет (1000+1000) = 2000 рублей.\n
При установленной цели х3, она будет равна (1000 + 1000) * 3 = 6000 рублей. В данном случае, как только сумма вашего реального и бонусного балансов достигнет 6000 рублей, бонусный баланс автоматически перейдет на основной счет.
      `,
  },
  {
    title: 'Могу ли я совершить вывод средств с активным бонусом?',
    description: 'Вывод средств с активным бонусом недоступен. Завершите или отмените бонус, для успешного вывода.',
  },
  {
    title: 'Как активировать депозитные бонусы?',
    description:
      'Чтобы активировать депозитный бонус, у вас на реальном счету должны быть средства. Для активации - нажмите кнопку «Активировать» на доступном бонусе.',
  },
  {
    title: 'Где можно отыграть бонус?',
    description:
      'Отыгрыш активного бонуса доступен во всех играх, находящихся в категории «Доступные с бонусом». Вы можете найти эту категорию в игровом лобби для удобного выбора подходящих игр.',
  },
];

export const loyalty = [
  {
    title: 'Есть ли у вас программа лояльности для постоянных игроков?',
    description:
      'Да, у нас есть программа лояльности для постоянных игроков! Она включает 6 уровней, каждый из которых открывает доступ к эксклюзивным бонусам, привилегиям и подаркам.',
  },
  {
    title: 'Как происходит начисление очков лояльности?',
    description: `
Очки лояльности начисляются в зависимости от вашего оборота средств. Чем больше ваш оборот, тем выше уровень лояльности:\n
Зеленый: от 0 RUB\n
Белый: от 50 000 RUB\n
Бронза: от 500 000 RUB\n
Серебро: от 1 500 000 RUB\n
Золото: от 3 000 000 RUB\n
Платина: от 5 000 000 RUB\n
Если за 30 дней с момента последнего обновления статуса не будет выполнен минимальный оборот, необходимый для текущего уровня, ваш статус будет пересчитан на основе актуального оборота. Вы всегда можете снова повысить уровень, достигнув требуемого оборота в течение следующего периода.
    `,
  },
];

export const other = [
  {
    title: 'Безопасно ли у вас играть?',
    description:
      'Казино Восход использует новейшие технологии шифрования для защиты данных игроков. Это означает, что ваша личная информация и финансовые данные защищены от посторонних лиц.',
  },
  {
    title: 'У вас лицензионный софт?',
    description:
      'Игры в казино Восход предоставляются лицензированными провайдерами, которые регулярно проходят проверки международными аудиторами. Это значит, что настройки и установленный RTP соответствуют всем нормам и стандартам качества.',
  },
  {
    title: 'Есть ли у вас реферальная или партнерская система?',
    description:
      'Если вы заинтересованы в сотрудничестве, свяжитесь с нами по электронной почте aff@vocxod.com. Мы будем рады обсудить условия и возможности партнерства.',
  },
];
