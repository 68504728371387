import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import MobBanner from '../../../assets/components/Loyalty/MobBanner.png';
import TabletBanner from '../../../assets/components/Loyalty/TabletBanner.png';
import { ThemeContext } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { LoyaltyBadge } from '../../atoms/LoyaltyBadge/LoyaltyBadge';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './LoyaltyPage.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Программа лояльности</title>
    <meta
      name='description'
      content='Присоединяйтесь к программе лояльности казино Восход и получайте награды за вашу активность. Узнайте, как накопить баллы и обменять их на ценные призы и бонусы.'
    />
  </Helmet>
);

const LoyaltyPage = () => {
  const { t } = useTranslation('Loyalty');
  const { isMobile, isTablet, isDesktop } = useWindowSize();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <section className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{theme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {theme === 'dark' ? <ArrowLeftSvg /> : <ArrowLeftDarkSvg />}
            <h1 className={styles.title}>{t('Loyalty')}</h1>
          </div>
        </div>
        <div className={styles.content}>
          {isDesktop && (
            <div className={styles.badgeContainer}>
              <LoyaltyBadge level={1} isLarge />
              <LoyaltyBadge level={2} isLarge />
              <LoyaltyBadge level={3} isLarge />
              <LoyaltyBadge level={4} isLarge />
              <LoyaltyBadge level={5} isLarge />
              <LoyaltyBadge level={6} isLarge />
            </div>
          )}
          {!isDesktop && (
            //TODO переделать на баджики как на десктопе
            <div className={styles.badgeContainer}>
              <img src={isTablet ? TabletBanner : MobBanner} alt='loyalty_banner' />
            </div>
          )}
          <div className={styles.textContent}>
            <h2>Как работает программа лояльности в казино VOCXOD?</h2>
            <p>
              Программа лояльности в онлайн-казино Восход — это уникальная возможность для игроков не только
              наслаждаться игровым процессом, но и получать эксклюзивные награды, привилегии и бонусы на каждом уровне.
              Система лояльности включает 6 уровней, каждый из которых открывает новые возможности и подарки для
              активных пользователей.
            </p>
            <h3>Какие уровни включены в программу?</h3>
            <p>
              Чтобы получить доступ к новым уровням и все более ценным наградам, необходимо сделать определенный оборот
              ставок:
            </p>
            <ul>
              <li>
                <strong>Зеленый:</strong> за регистрацию
              </li>
              <li>
                <strong>Белый:</strong> 50 000 RUB
              </li>
              <li>
                <strong>Бронза:</strong> 500 000 RUB
              </li>
              <li>
                <strong>Серебро:</strong> 1 500 000 RUB
              </li>
              <li>
                <strong>Золото:</strong> 3 000 000 RUB
              </li>
              <li>
                <strong>Платина:</strong> 5 000 000 RUB
              </li>
            </ul>
            <h3>Что получают игроки с каждым новым уровнем?</h3>
            <p>
              С каждым повышением уровня лояльности вы открываете для себя больше возможностей. В первую очередь, это
              увеличенные лимиты на вывод средств, которые позволяют без ограничений наслаждаться игрой. Помимо этого,
              каждый новый уровень дает доступ к уникальным бонусам, эксклюзивным предложениям и персонализированным
              наградам, что делает игру еще более увлекательной и прибыльной.
            </p>
            <h3>Обновление уровней</h3>
            <p>
              Уровни лояльности обновляются, если за последние 30 дней не был выполнен необходимый оборот для текущего
              или следующего уровня. Если требуемый оборот не достигнут, уровень будет пересчитан на основании
              актуальных ставок, и для повышения потребуется выполнить условия за следующий период.
            </p>
            <p>
              Не упустите шанс стать частью программы лояльности казино VOCXOD и откройте для себя мир привилегий и
              эксклюзивных предложений!
            </p>
          </div>
        </div>
      </section>

      {!isMobile && <Footer />}
      {isMobile && <NavBarBottom />}
    </>
  );
};

export default LoyaltyPage;
