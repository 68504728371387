import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../constants/constants';
import { registerUrl } from '../../../../constants/ssoConstants';
import { UserContext } from '../../../../context/UserContext';
import styles from './GameItem.module.scss';

export const GameItem = ({ id, game, activeGameId, setActiveGameId }) => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const imgSource = game?.main_image;
  const img = imgSource?.replace(/__domain__/g, STATIC_URL);

  const handleGameClick = () => {
    if (!isAuthenticated) {
      window.location = registerUrl;
      return;
    }

    if (activeGameId === id) {
      navigate(`/game/${game?.key}`);
    } else {
      setActiveGameId(id);
    }
  };

  return (
    <div
      onClick={handleGameClick}
      className={styles.gameIco}
      style={{ transform: activeGameId === id ? 'scale(0.95)' : 'scale(1)' }}
    >
      <img src={img} alt={`VOCXOD_${game?.translates?.en?.name}`} />
    </div>
  );
};
